import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import _, {
  chain, //, uniqueId
  groupBy
} from 'lodash';
import { AppThunk } from 'src/store';
import {
  ICreateDealWithDetailsInput,
  IProviderDealFilter,
  Member,
  ProviderDealFilterService,
  IDeal,
  IPipelineStage,
  PipelineStageService,
  DealService
} from 'shared';
// import { PipelineService } from 'shared';
// import { IDeal } from 'shared';
// import { IPipelineStage } from 'shared';
// import { PipelineStageService } from 'shared';
// import { DealService } from 'shared';

interface LeadsState {
  isLoaded: boolean;
  pipelineId: string;
  providerDealFilterId: string;
  lists: IPipelineStage[];
  filter: IProviderDealFilter;
  selectedCard?: IDeal;
  cardListMap: { [x: string]: string };
  members: Member[];
}

const initialState: LeadsState = {
  isLoaded: false,
  pipelineId: null,
  providerDealFilterId: null,
  selectedCard: null,
  cardListMap: {},
  filter: null,
  lists: [],
  members: []
};

const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    getBoard(
      state: LeadsState,
      action: PayloadAction<{
        lists: IPipelineStage[];
        pipelineId: string;
        cardListMap: any;
        filter: IProviderDealFilter;
        providerDealFilterId: string;
      }>
    ) {
      const {
        lists,
        pipelineId,
        cardListMap,
        filter,
        providerDealFilterId
      } = action.payload;

      state.pipelineId = pipelineId;
      state.lists = lists;
      state.cardListMap = cardListMap;
      state.isLoaded = true;
      state.filter = filter;
      state.providerDealFilterId = providerDealFilterId;
    },

    setSelectedCard(state: LeadsState, action: PayloadAction<{ card: IDeal }>) {
      const { card } = action.payload;
      state.selectedCard = card;
    },

    createList(
      state: LeadsState,
      action: PayloadAction<{ list: IPipelineStage }>
    ) {
      const { list } = action.payload;
      state.lists.push(list);
    },

    updateList(
      state: LeadsState,
      action: PayloadAction<{ list: IPipelineStage }>
    ) {
      const { list } = action.payload;
      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    deleteList(state: LeadsState, action: PayloadAction<{ listId: string }>) {
      const { listId } = action.payload;
      state.lists = _.filter(state.lists, list => list.id !== listId);
    },

    createCard(state: LeadsState, action: PayloadAction<{ card: IDeal }>) {
      const { card } = action.payload;
      const list = _.find(
        state.lists,
        list => card.pipelineStageId === list.id
      );
      list.deals.push(card);

      state.cardListMap[card.id] = card.pipelineStageId;

      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    updateCard(
      state: LeadsState,
      action: PayloadAction<{ cardId: string; card: IDeal }>
    ) {
      const { card, cardId } = action.payload;
      const list = _.find(
        state.lists,
        list => card.pipelineStageId === list.id
      );
      list.deals = list.deals.map(oldCard => {
        return cardId === oldCard.id ? card : oldCard;
      });

      state.cardListMap[card.id] = card.pipelineStageId;

      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    moveCard(
      state: LeadsState,
      action: PayloadAction<{
        cardId: string;
        position: number;
        listId?: string;
      }>
    ) {
      const { cardId, position, listId } = action.payload;

      const oldListId = state.cardListMap[cardId];

      const oldList = _.find(state.lists, list => oldListId === list.id);
      const card = _.find(oldList.deals, deal => cardId === deal.id);
      oldList.deals = oldList.deals.filter(card => card.id !== cardId);

      const newList = _.find(state.lists, list => list.id === listId);
      if (listId) {
        card.pipelineStageId = listId;
        state.cardListMap[cardId] = listId;
        if (position >= 0) {
          newList.deals.splice(position, 0, card);
        } else {
          newList.deals.push(card);
        }
      } else {
        if (position) {
          oldList.deals.splice(position, 0, card);
        } else {
          oldList.deals.push(card);
        }
      }

      state.lists = state.lists.map(item => {
        if (newList && item.id === newList.id) return newList;
        else if (item.id === oldList.id) {
          return oldList;
        } else {
          return item;
        }
      });
      // state.lists = _.map(state.lists, (list) => list.id != newList.id ? list : newList);
    },

    deleteCard(state: LeadsState, action: PayloadAction<{ cardId: string }>) {
      const { cardId } = action.payload;

      const listId = state.cardListMap[cardId];
      const list = _.find(state.lists, list => listId === list.id);
      list.deals = list.deals.filter(item => item.id !== cardId);
      state.lists = state.lists.map(item => {
        return list.id === item.id ? list : item;
      });
    }
  }
});

export const reducer = slice.reducer;

// const pipelineService = PipelineService.getInstance<PipelineService>();
const providerDealFilterService = ProviderDealFilterService.getInstance<
  ProviderDealFilterService
>();
const pipelineStageService = PipelineStageService.getInstance<
  PipelineStageService
>();
const dealService = DealService.getInstance<DealService>();

export const getBoard = (): AppThunk => dispatch => {
  // return pipelineService.getWithDetails({
  //   PipelineType: 'Lead',
  //   PipelineRelationType: 7,
  // }).then((resp) => {
  //   const lists: IPipelineStage[] = resp.data.pipelineStages;
  //   const cardListMap = chain(lists).map((list) => list.deals).flatten().keyBy('id').mapValues('pipelineStageId').value();
  //   dispatch(slice.actions.getBoard({
  //     pipelineId: resp.data.id,
  //     cardListMap,
  //     lists,
  //   }));
  // });
  return providerDealFilterService
    .getByProvider({
      PipelineType: 'Lead',
      PipelineRelationType: 7
    })
    .then(resp => {
      const filter: IProviderDealFilter = {
        baseStatus: resp.data.baseStatus,
        providerId: resp.data.providerId,
        leadDates: resp.data.leadDates,
        providerDealFiltersEnrollmentSchedules:
          resp.data.providerDealFiltersEnrollmentSchedules,
        pipelineType: resp.data.pipelineType ? resp.data.pipelineType : 'Lead',
        pipelineRelationType: resp.data.pipelineRelationType
          ? resp.data.pipelineRelationType
          : 7
      };
      const lists: IPipelineStage[] = resp.data.pipeline.pipelineStages;
      const cardListMap = chain(lists)
        .map(list => list.deals)
        .flatten()
        .keyBy('id')
        .mapValues('pipelineStageId')
        .value();
      dispatch(
        slice.actions.getBoard({
          pipelineId: resp.data.id,
          cardListMap,
          lists,
          filter,
          providerDealFilterId: resp.data.id
        })
      );
    });
};

export const updateBoard = (
  id: string,
  request: IProviderDealFilter
): AppThunk => dispatch => {
  return providerDealFilterService
    .updateFilterByProvider(id, request)
    .then(resp => {
      const filter: IProviderDealFilter = {
        baseStatus: resp.data.baseStatus,
        providerId: resp.data.providerId,
        leadDates: resp.data.leadDates,
        providerDealFiltersEnrollmentSchedules:
          resp.data.providerDealFiltersEnrollmentSchedules,
        pipelineType: resp.data.pipelineType ? resp.data.pipelineType : 'Lead',
        pipelineRelationType: resp.data.pipelineRelationType
          ? resp.data.pipelineRelationType
          : 7
      };
      const lists: IPipelineStage[] = resp.data.pipeline.pipelineStages;
      const cardListMap = chain(lists)
        .map(list => list.deals)
        .flatten()
        .keyBy('id')
        .mapValues('pipelineStageId')
        .value();
      dispatch(
        slice.actions.getBoard({
          pipelineId: resp.data.id,
          cardListMap,
          lists,
          filter,
          providerDealFilterId: resp.data.id
        })
      );
    });
};

export const setDealsInList = (deals: IDeal[]): AppThunk => (
  dispatch,
  getState
) => {
  const { leads } = getState();

  const dealsByPipelineStageId = groupBy(deals, 'pipelineStageId');

  for (const list of leads.lists) {
    const deals = dealsByPipelineStageId[list.id] || [];
    dispatch(
      slice.actions.updateList({
        list: {
          ...list,
          deals: deals
        }
      })
    );
  }
};

export const createList = (name: string): AppThunk => (dispatch, getState) => {
  return pipelineStageService
    .create({
      name,
      pipelineId: getState().leads.pipelineId
    })
    .then(resp => {
      const list = resp.data;
      dispatch(slice.actions.createList({ list }));
      return resp.data;
    });
};

export const updateList = (listId: string, update: any): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState().leads;

  const list = _.find(state.lists, { id: listId });

  const request = {
    ...list,
    ...update
  };

  return pipelineStageService.update(listId, request).then(resp => {
    dispatch(slice.actions.updateList({ list: request }));
    return resp.data;
  });
};

export const deleteList = (listId: string): AppThunk => dispatch => {
  return pipelineStageService.delete(listId).then(() => {
    dispatch(slice.actions.deleteList({ listId }));
  });
};

export const createCard = (listId: string, card: any): AppThunk => dispatch => {
  const request = {
    ...card,
    pipelineStageId: listId
  };
  // const tempId = uniqueId();

  return dealService.create(request).then(resp => {
    dispatch(
      slice.actions.createCard({
        card: resp.data
      })
    );
    return resp.data;
    //  dispatch(slice.actions.updateCard({ cardId: tempId, card: resp.data }));
  });
};

export const createWithDetails = (
  request: Partial<ICreateDealWithDetailsInput>
): AppThunk => dispatch => {
  return dealService.createWithDetails(request).then(resp => {
    const card = resp.data;
    if (request?.deal?.dealId) {
      const cardId = request?.deal?.dealId;

      dispatch(
        slice.actions.moveCard({
          cardId,
          position: null,
          listId: request.deal?.pipelineStageId
        })
      );
      dispatch(slice.actions.updateCard({ cardId, card }));
    } else {
      dispatch(slice.actions.createCard({ card }));
    }

    return resp.data;
  });
};

export const updateCard = (
  cardId: string,
  update: Partial<IDeal>
): AppThunk => (dispatch, getState) => {
  const state = getState().leads;
  const listId = state.cardListMap[cardId];
  const list = _.find(state.lists, { id: listId });
  const card = _.find(list.deals, deal => cardId === deal.id);
  const request = {
    ...card,
    ...update
  };
  return dealService.update(cardId, request).then(resp => {
    if (card.pipelineStageId !== request.pipelineStageId) {
      dispatch(
        slice.actions.moveCard({
          cardId: cardId,
          position: null,
          listId: request.pipelineStageId
        })
      );
    }
    dispatch(slice.actions.updateCard({ cardId, card: resp.data }));
    return resp.data;
  });
};

export const moveCard = (
  cardId: string,
  position: number,
  listId?: string,
  registerLead?: boolean
): AppThunk => (dispatch, getState) => {
  const state = getState().leads;
  const oldListId = state.cardListMap[cardId];
  const oldList = _.find(state.lists, list => oldListId === list.id);
  const card = _.find(oldList.deals, deal => cardId === deal.id);
  const oldPosition = oldList.deals.indexOf(card);

  dispatch(
    slice.actions.moveCard({
      cardId: cardId,
      position,
      listId
    })
  );

  let request = null;

  if (typeof registerLead !== 'undefined') {
    request = {
      ...card,
      dealOrder: position,
      ...(listId ? { pipelineStageId: listId } : {}),
      registerLead
    };
  } else {
    request = {
      ...card,
      dealOrder: position,
      ...(listId ? { pipelineStageId: listId } : {})
    };
  }

  return dealService.update(card.id, request).catch(error => {
    dispatch(
      slice.actions.moveCard({
        cardId: cardId,
        position: oldPosition,
        listId: oldListId
      })
    );
    throw error;
  });
};

export const deleteCard = (cardId: string): AppThunk => dispatch => {
  return dealService.delete(cardId).then(() => {
    dispatch(slice.actions.deleteCard({ cardId }));
  });
};

export const setSelectedCard = (card: IDeal): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedCard({ card }));
};

export default slice;
