import { createSlice } from '@reduxjs/toolkit';
import { FeatureFlagService } from 'shared';
import { AppThunk } from 'src/store';

export interface FeatureFlagState {
  Intercom: boolean;
  'Parent-Portal': boolean;
  'Premium-Plan': boolean;
  'Provider-WIP': boolean;
  'Program-Tagline': boolean;
  'Provider-Multi-User': boolean;
}

const initialState: FeatureFlagState = {
  Intercom: false,
  'Parent-Portal': false,
  'Premium-Plan': false,
  'Provider-WIP': false,
  'Program-Tagline': false,
  'Provider-Multi-User': false
};

const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags(state: FeatureFlagState, action: any) {
      state = { ...state, ...action.payload };
    }
  }
});

export const reducer = slice.reducer;

const featureFlagService = FeatureFlagService.getInstance<FeatureFlagService>();

export const getFeatureFlags = (providerId: string): AppThunk => dispatch => {
  return featureFlagService
    .getFeatureFlagsByProvider(providerId)
    .then(({ data }) => {
      dispatch(slice.actions.setFeatureFlags(data));
      return data;
    });
};

export default slice;
