import {
  Box,
  Button,
  Hidden,
  Link,
  makeStyles,
  Popover,
  Theme,
  Typography
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'src/store';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AuthService } from 'src/lib/authService';
import useAuth from 'src/hooks/useAuth';
import SettingsDialog from 'src/components/SettingsDialog';
// import SettingsDialog from 'src/components/core/Dialogs/Settings';

const useStyles = makeStyles((theme: Theme) => ({
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  schoolTextWrapper: {
    fontSize: 20,
    fontStyle: 'normal',
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 15px'
  },
  schoolLogo: {
    paddingTop: 3
  },
  routerLinkStyle: {
    textDecoration: 'none',
    width: '100%'
  },
  headingTxt: {
    fontSize: '18px',
    padding: '0 10px',
    float: 'left',
    // width: '85%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  button: {
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  logoutLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  navItemLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  profileImage: {
    borderRadius: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: 'cover'
  },
  userImageWrapper: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userImageWrapperTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25
  },
  username: {
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    textAlign: 'left',
    fontWeight: 500,
    fontSize: 14
  },
  usernamePopover: {
    textTransform: 'capitalize',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: 'left',
    paddingLeft: theme.spacing(2)
  },
  userEmail: {
    color: theme.palette.text.secondary,
    fontFamily: '"Source Sans Pro", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  userDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  userDetailsContainerBorder: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #DCDCDC'
  },
  userDetailsContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  userDetailsContentWrapperTop: {
    display: 'flex',
    flexDirection: 'column'
  },
  topBar: {
    background: '#F3FAFA'
  },
  headerActionIcon: {
    width: '100%',
    height: '100%',
  }
}));

const TopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { provider, profileUser } = useSelector(state => ({
    provider: state['currentUser'].provider,
    profileUser: state['currentUser'].user
  }));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(
    false
  );

  const { user, providerId, logout } = useAuth();
  const authService = AuthService.getInstance();

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsModalClose = useCallback(() => {
    setIsSettingsModalOpen(false);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      window.analytics?.track('Signed Out', {
        user_id: user?.profile?.sub,
        provider_id: providerId
      });
      await authService.logout();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        px={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={72}
        className={classes.topBar}
      >
        <Box>
          <Hidden mdDown>
            <Box className={classes.logoWrapper}>
              <RouterLink className={classes.routerLinkStyle} to="/app/">
                <Box className={classes.schoolTextWrapper}>
                  {provider?.logo && (
                    <img
                      className={classes.schoolLogo}
                      width="32px"
                      height="100%"
                      src={provider.logo}
                      alt="school-logo"
                    />
                  )}
                  <div className={classes.headingTxt}>{provider?.name}</div>
                </Box>
              </RouterLink>
            </Box>
          </Hidden>
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginRight={2}>
            <Button className={classes.button}>
              <Box className={classes.userDetailsContainer}>
                <Box className={classes.userImageWrapperTop}>
                  <img src="/static/icons/help.svg" alt="help" className={classes.headerActionIcon} />
                </Box>
                <Box
                  paddingTop={0.3}
                  className={classes.userDetailsContentWrapperTop}
                >
                  <Typography className={classes.username}>Help</Typography>
                </Box>
              </Box>
            </Button>
          </Box>
          <Box marginRight={2}>
            <Button
              className={classes.button}
              onClick={() => setIsSettingsModalOpen(true)}
            >
              <Box className={classes.userDetailsContainer}>
                <Box className={classes.userImageWrapperTop}>
                  <img src="/static/icons/settings.svg" alt="settings" className={classes.headerActionIcon} />
                </Box>
                <Box
                  paddingTop={0.3}
                  className={classes.userDetailsContentWrapperTop}
                >
                  <Typography className={classes.username}>Settings</Typography>
                </Box>
              </Box>
            </Button>
          </Box>
          <Box>
            <Button className={classes.button} onClick={handleProfileClick}>
              <Box className={classes.userDetailsContainer}>
                <Box className={classes.userImageWrapperTop}>
                  {profileUser?.imageUrl ? (
                    <img
                      className={classes.profileImage}
                      src={profileUser?.imageUrl}
                      alt="avatar"
                    />
                  ) : (
                    <AccountCircleIcon fontSize="large" />
                  )}
                </Box>
                <Box className={classes.userDetailsContentWrapperTop}>
                  <Typography className={classes.username}>profile</Typography>
                </Box>
              </Box>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <Box paddingY={2} className={classes.userDetailsContainerBorder}>
                <Box className={classes.userDetailsContentWrapper}>
                  <Typography className={classes.usernamePopover}>
                    {profileUser?.name} {profileUser?.surname}
                  </Typography>
                </Box>
                <Box className={classes.userImageWrapper}>
                  {profileUser?.imageUrl ? (
                    <>
                      <img
                        className={classes.profileImage}
                        src={profileUser?.imageUrl}
                        alt="avatar"
                      />
                    </>
                  ) : (
                    <>
                      <AccountCircleIcon fontSize="large" />
                    </>
                  )}
                </Box>
              </Box>
              <Box
                paddingX={2}
                paddingY={1}
                borderBottom="1px solid #DCDCDC"
                width="100%"
              >
                <Link
                  className={classes.navItemLink}
                  component={RouterLink}
                  to="/app/my-profile"
                >
                  My Profile
                </Link>
              </Box>
              <Box
                paddingX={2}
                paddingY={1}
                borderBottom="1px solid #DCDCDC"
                width="100%"
              >
                <Link
                  className={classes.navItemLink}
                  component={RouterLink}
                  to="/app/my-organization"
                >
                  My Organization
                </Link>
              </Box>
              <Box paddingX={2} paddingY={1} width="100%">
                <Link className={classes.logoutLink} onClick={handleLogout}>
                  Logout
                </Link>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
      <SettingsDialog
        onClose={handleSettingsModalClose}
        isOpen={isSettingsModalOpen}
        selectedSettings="registration"
        isAutoRedirectToTab
      />
    </>
  );
};

export default TopBar;
