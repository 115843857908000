///  <reference types="@types/segment-analytics" />
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import { SubscriptionService, User } from 'shared';
import SplashScreen from 'src/components/SplashScreen';
import { instanceApi } from 'shared';
import { AuthService } from 'src/lib/authService';
import { IUserProvider } from 'shared';
import { ProviderService, UserProviderService } from 'shared';
import { MediaFileService } from 'shared';
import { useHistory, useLocation } from 'react-router-dom';
import userflow from 'userflow.js';
import { useDispatch } from 'src/store';
import { getCurrentUserSubscription, getFeatureFlagsByProvider, getUserById } from 'src/slices/current-user';
import { useIntercom } from 'react-use-intercom';
import LogRocket from 'logrocket';
import { getFeatureFlags } from 'src/slices/feature-flags';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  accessToken?: string;
  providerId?: string;
  provider?: IUserProvider;
  userProfile?: User;
}

interface AuthContextValue extends AuthState {
  method: 'OIDC',
  login: () => Promise<void>;
  logout: () => void;
  setLogin: (accessToken: string, user: any) => void;
  register: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    accessToken?: string;
    providerId?: string;
    provider?: IUserProvider;
    userProfile?: User;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    providerId: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  accessToken: null,
  provider: null,
  userProfile: null,
};

const setSession = (accessToken: string | null, tenantId: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    instanceApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete instanceApi.defaults.headers.common.Authorization;
  }

  if (tenantId) {
    localStorage.setItem('tenantId', tenantId);
    instanceApi.defaults.headers.common.__tenant = tenantId;
  } else {
    localStorage.removeItem('tenantId');
    delete instanceApi.defaults.headers.common.__tenant;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {

      return {
        ...state,
        isInitialised: true,
        ...action.payload
      };
    }
    case 'LOGIN': {
      const { user, providerId } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        providerId
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    // userpilot: any;
    // fcWidget: any;
    // $crisp: any;
    Intercom: any;
    Userback: any;
  }
}

const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    const url = new URL(window.location.href);
    var domain = url.hostname.substring(url.host.lastIndexOf(".") + 1);

    window.analytics.page(location.pathname, null, {
      environment: process.env.NODE_ENV,
      user: "Provider",
      domain
    });
    // window.userpilot?.reload();
  }, [location])
}

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'OIDC',
  login: () => Promise.resolve(),
  logout: () => { },
  setLogin: () => { },
  register: () => Promise.resolve()
});

const authService = AuthService.getInstance();
const userProviderService = UserProviderService.getInstance<UserProviderService>();
const providerService = ProviderService.getInstance<ProviderService>();
const subscriptionService = SubscriptionService.getInstance<SubscriptionService>();

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {

  usePageViews();

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const sliceDispatch = useDispatch();
  const history = useHistory();
  const { boot } = useIntercom();

  /**
   * Redirects to OIDC login page
   */
  const login = async () => {
    await authService.login();
  };

  const logout = () => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  const afterLogin = useCallback(
    async () => {
      const providerId = await userProviderService.getCurrentProvider().then(({ data }) => data);
      const provider = await providerService.get(providerId).then(({ data }) => data);
      const userProvider = await userProviderService.getAll().then(({ data }) => {
        return data?.items[0];
      });
      const user = await authService.getUser();

      let subscription = ''

      try {
        let response = await subscriptionService.getProviderSubscription();
        subscription = await response.data.subscription.edition.displayName;
      } catch (ex) {
        console.log('Subscription data not loaded');
      }


      await MediaFileService.init();

      if (
        provider?.providerRegistrySubscriptions?.length === 0
        || !userProvider
        || !(userProvider?.organizationSize >= 0)
      ) {
        history.push('/app/dashboard?initialSetup=true');
      }

      let featureFlags = null;
      try {
        if (providerId) {
          featureFlags = await sliceDispatch(getFeatureFlags(providerId));
        }
      } catch (ex) {
        console.log('Feature Flags data not loaded');
      }

      if (featureFlags['Intercom'] === true) {
        boot({
          // customAttributes: {
          //   location: provider?.addressLocation?.fullAddress,
          //   type: 'Provider',
          //   providerName: provider?.name,
          //   firstName: user?.profile?.given_name,
          //   lastName: user?.profile?.family_name,
          //   subscription,
          //   createdAt: provider?.creationTime,
          // },
          email: user?.profile?.email,
          name: provider?.name,
          userId: user?.profile?.sub,
          hideDefaultLauncher: subscription === '' && provider?.providerRegistrySubscriptions?.length === 0
        });
      }

      if(window.Userback) {
        window.Userback.setName(`${user?.profile?.given_name} ${user?.profile?.family_name}`); 
        window.Userback.setEmail(user?.profile?.email);
        window.Userback.setData({
            providerName: provider?.name,
            userId: user?.profile?.sub,
            email: user?.profile?.email,
            userName: `${user?.profile?.given_name} ${user?.profile?.family_name}`
        });
      }

      window.analytics?.track("Signed In", { provider_id: providerId, user_id: user?.profile?.sub });



      await sliceDispatch(getCurrentUserSubscription());
      await sliceDispatch(getUserById(providerId));
      try {
        await sliceDispatch(getFeatureFlagsByProvider(providerId));
      } catch (error) {
      }

      // Commented the onBoarding flow redirection.
      /*
      const onboardingStepStatus = await sliceDispatch(getStepStatus());

      if (!onboardingStepStatus.isSkipped || onboardingStepStatus.isCompleted) {
        const step = lastOnboardingStep(onboardingStepStatus);

        if (step) {
          history.push(step?.section?.routerLink);
          sliceDispatch(setMainStep(step.mainStep));
          sliceDispatch(setActiveStep(step.activeStep));
          if (step.tourStep) {
            sliceDispatch(setTourStep(step.tourStep));
          }
          if (step.profileStep) {
            sliceDispatch(setProfileStep(step.profileStep));
          }
        }
      }
      */

      return provider;
    },
    [sliceDispatch, history, boot]);

  const setLogin = async (accessToken, user) => {
    setSession(accessToken, user.profile.tenantid);
    const provider = await afterLogin();

    let subscription = null

    try {
      let response = await subscriptionService.getProviderSubscription();
      subscription = await response.data.subscription.edition.displayName;
    } catch (ex) {
      console.log('Subscription data not loaded');
    }

    // window.userpilot?.identify(
    //   user.profile.sid,
    //   {
    //     role: 'Provider',
    //     providerId: provider?.id
    //   })


    // window.Intercom("boot", {
    //   app_id: "k07tty3e",
    //   name: user?.profile?.given_name, // Full name
    //   email: user?.profile?.email, // Email address
    //   created_at: provider?.creationTime // Signup date as a Unix timestamp
    // });

    userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    userflow.identify(user.profile.sid,
      {
        role: 'Provider',
        providerId: provider?.id,
        email: user?.profile?.email,
        provider_state: provider?.addressLocation?.state,
        name: user?.profile?.given_name,
        userId: user?.profile?.sub,
        provider_zipcode: provider?.addressLocation?.zipCode,
        createdAt: provider?.creationTime,
        subscription_plan: subscription
      });

      // logrocket integration
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      LogRocket.identify(user.profile.sid,
        {
          role: 'Provider',
          providerId: provider?.id,
          email: user?.profile?.email,
          provider_state: provider?.addressLocation?.state,
          name: user?.profile?.given_name,
          userId: user?.profile?.sub,
          provider_zipcode: provider?.addressLocation?.zipCode,
          createdAt: provider?.creationTime,
          subscription_plan: subscription
        })



    // window.$crisp.push(["set", "user:email", [user?.profile?.email]]);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        providerId: provider?.id,
      }
    });
  };

  /**
   * Redirects to OIDC register page
   */
  const register = async () => { };
  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const tenantId = window.localStorage.getItem('tenantId');

        if (accessToken && tenantId) {
          setSession(accessToken, tenantId);

          // TODO: we can make call to API here (we get token already) to get more details about user, e.g. avatar
          // For now we use what we get from OIDC provider instead (but that not include avatar and probably other things)
          // const response = await instanceApi.get<{ user: User; }>('/api/account/me');
          // const { user } = response.data;

          const user = await authService.getUser();

          const provider = await afterLogin();

          // try {
          //   window.fcWidget?.user?.setProperties({
          //     center: provider?.name,
          //     email: user?.profile?.email,
          //     id: user?.profile?.sub,
          //     name: `${user?.profile?.given_name} ${user?.profile?.family_name}`
          //   });
          // } catch (error) {

          // }

          try {
            window.analytics?.identify(user?.profile?.sub, {
              providerId: provider?.id,
              tenantId: user?.profile?.tenantid,
              email: user?.profile?.email,
              provider_state: provider?.addressLocation?.state,
              name: user?.profile?.given_name,
              userId: user?.profile?.sub,
              provider_zipcode: provider?.addressLocation?.zipCode,
              createdAt: provider?.creationTime,
            });
          } catch (error) {

          }
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              accessToken,
              user,
              providerId: provider?.id,
            }
          });

        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
            user: null
          }
        });
      }
    };

    initialise();

  }, [afterLogin]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'OIDC',
        login,
        logout,
        register,
        setLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;