import { createSlice } from '@reduxjs/toolkit';
import { UserProviderService } from 'shared';
import { AppThunk } from 'src/store';

export interface SettingsState {
  userSettings: any;
}

const initialState: SettingsState = { userSettings: null };

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUserSettings(state: SettingsState, action: any) {
      state.userSettings = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const userProviderService = UserProviderService.getInstance<
  UserProviderService
>();

export const getUserSettings = (providerId: string): AppThunk => dispatch => {
  userProviderService.getProviderUserSettings(providerId).then(({ data }) => {
    dispatch(slice.actions.setUserSettings(data));
  });
  //   return calendarEventService.getEventByType('Tour').then(({ data }) => {
  //     dispatch(
  //       slice.actions.setTourMeetingDetails({
  //         ...TourInfoMapper({
  //           ...data,
  //           bufferAfterMeeting: String(data.bufferAfterMeeting)
  //         })
  //       })
  //     );
  //     return {
  //       ...TourInfoMapper({
  //         ...data,
  //         bufferAfterMeeting: String(data.bufferAfterMeeting)
  //       })
  //     };
  //   });
};

export default slice;
