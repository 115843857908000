import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  makeStyles,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  // IconButton,
  Paper,
  // InputBase,
  ButtonBase,
  IconButton
} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Theme } from 'src/theme';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import SelectWithLabel from 'src/pages/tours/ToursView/components/SelectWithLabel';
import { useDispatch, useSelector } from 'src/store';
import { CalendarEventService, ProviderSettingsService } from 'shared';
import useAuth from 'src/hooks/useAuth';
// import SearchIcon from '@material-ui/icons/Search';
// import MicIcon from '@material-ui/icons/Mic';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BulbIcon from '../icons/BulbIcon';
import ToggleSwitch from './ToggleSwitch';
import { useToasts } from '../core/ToastManager';
import { getTourSettings } from 'src/slices/tour';
import DayButton from './DayButton';
import MessageBox from './MessageBox';
import { getInboxSetting } from 'src/slices/chat';

export interface SettingsDialogProps {
  isOpen: boolean;
  isAutoRedirectToTab?: boolean;
  onClose?: () => void;
  selectedSettings?: string;
  // tourSettings?: any;
  getTourSettingsInfo?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paperFullScreen: {
    backgroundColor: '#F4F4F4'
  },
  paper: {
    borderRadius: theme.spacing(2),
    width: 796
  },
  confirmButton: {
    marginLeft: theme.spacing(2)
  },
  contentText: {
    color: theme.palette.text.hint
  },
  contentHighlight: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1.5),
    fontSize: 18,
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 500
  },
  contentHighlightCapitalize: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1.5),
    fontSize: 18,
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(2),
    border: '1px solid rgba(104, 127, 146, 0.2)',
    borderRadius: theme.spacing(2)
  },
  saveContainer: {
    background: theme.palette.common.white,
    height: 80,
    width: '100%',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(104, 127, 146, 0.2)'
  },
  leftSection: {
    display: 'flex',
    width: '30%',
    alignItems: 'center'
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  },
  textContainer: {
    marginLeft: 10,
    marginRight: 10
  },
  saveButtonContainer: {
    marginRight: theme.spacing(3),
    height: theme.spacing(6),
    width: theme.spacing(13.75),
    textTransform: 'none',
    fontWeight: 'bold',
    background: theme.palette.secondary.main,
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(4),
    color: theme.palette.common.white,
    '&:hover': {
      background: '#f16924',
      color: theme.palette.common.white
    }
  },
  discardButtonContainer: {
    marginLeft: 16,
    marginRight: 16,
    height: theme.spacing(6),
    width: theme.spacing(12.75),
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)'
  },
  closeImage: {
    width: 14,
    height: 14
  },
  closeWrapper: {
    position: 'absolute',
    right: 25
  },
  headingWrapper: {
    height: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(104, 127, 146, 0.2)',
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  dialogHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    marginBottom: '18px',
    color: '#333333'
  },
  noPadding: {
    padding: '0 !important'
  },
  textContent: {
    fontFamily: '"Roboto", sans-serif',
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  navItem: {
    color: '#333333',
    fontSize: 14,
    marginBottom: '16px',
    fontWeight: 500,
    fontFamily: '"Roboto", sans-serif',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    background: '#DCDCDC',
    borderRadius: 4,
    height: 40
  },
  navItemUnselected: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: '16px',
    fontFamily: '"Roboto", sans-serif',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: 4,
    height: 40
  },
  linkButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  iconButton: {
    padding: 10
  },
  navHeading: {
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#838383'
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20,
    color: '#585858',
    fontSize: '2rem'
  },
  settingTitle: {
    color: '#000',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  startTime: {
    marginRight: theme.spacing(4)
  },
  endTime: {},
  daysInput: {
    width: 60,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  // searchContainer: {
  //   padding: '2px 4px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginBottom: '5px',
  //   boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
  // },
  // searchInput: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1
  // }
  paperContainer: {
    boxShadow: '0px 2px 4px rgb(88 88 88 / 25%)',
    borderRadius: 12,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  primaryText: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#000000',
    marginBottom: theme.spacing(2)
  },
  radioGroupRoot: {
    flexDirection: 'row'
  },
  radioLabelRoot: {
    marginRight: 60
  },
  primaryLabel: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#585858'
  },
  secondaryLabel: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#585858',
    opacity: 0.75
  },
  radioRoot: {
    color: '#838383'
  },
  colorSecondary: {
    '&$checked': {
      color: theme.palette.third.main
    }
  },
  checked: {},
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.palette.common.white,
    border: '2px solid #4E9AAC',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  tooltipText: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#585858',
  },
  tooltipButtonContainer: {
    marginLeft: theme.spacing(6)
  },
  tooltipClose: {
    color: '#838383'
  },
  tooltipCloseIcon: {
    fontSize: 14
  },
  tooltipIconContainer: {
    marginRight: theme.spacing(2)
  },
  tooltipIcon: {
    fontSize: theme.spacing(6)
  }
}));

const tourLengthOptions = [
  { value: 15, label: '15 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 60, label: '1 hour' }
];

const daysOfWeek = [
  { title: 'Mon', isChecked: true },
  { title: 'Tue', isChecked: true },
  { title: 'Wed', isChecked: true },
  { title: 'Thu', isChecked: true },
  { title: 'Fri', isChecked: true },
  { title: 'Sat', isChecked: false },
  { title: 'Sun', isChecked: false },
]

const weekOptions = [
  { value: 28, label: '4 weeks' },
  { value: 42, label: '6 weeks' },
  { value: 56, label: '8 weeks' },
  { value: 84, label: '12 weeks' }
];

const tourGapOptions = [
  { value: 0, label: 'No tour gap' },
  { value: 15, label: '15 minutes' }
];

const providerSettingsService = ProviderSettingsService.getInstance<
  ProviderSettingsService
>();

const calendarEventService = CalendarEventService.getInstance<
  CalendarEventService
>();

const SettingsDialog: FC<SettingsDialogProps> = ({
  isOpen,
  onClose,
  selectedSettings = null,
  isAutoRedirectToTab,
  getTourSettingsInfo
}) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [tourLength, setTourLength] = useState<number | null>(null);
  const [noOfDays, setNoOfDays] = useState<number | null>(null);
  const [bufferAfterMeeting, setBufferAfterMeeting] = useState<string | null>(
    null
  );
  const [activeSettings, setActiveSettings] = useState<any>('tours');
  const [inboxSettings, setInboxSettings] = useState<any>({
    defaultCreationMessageType: 0,
    inboxLayoutMessageCardType: 0
  });
  const [showTooltip, setTooltip] = useState<boolean>(true);
  const [tourSettings, setTourSettings] = useState<any>(null);
  const [selectedStartTime, handleStartTimeChange] = useState<any>(null);
  const [selectedCloseTime, handleCloseTimeChange] = useState<any>(null);
  const [providerSettings, setProviderSettings] = useState<any>({
    onlineRegistrations: false,
    registrationPayments: false,
    autoRegistrationApproval: false,
    archivedLeadsDays: null
  });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { addToast } = useToasts();
  const { providerId } = useAuth();
  const sliceDispatch = useDispatch();
  const location = useLocation();
  const tourMeetingDetails = useSelector(
    state => state.tour.tourMeetingDetails
  );

  useEffect(() => {
    if (isAutoRedirectToTab && isOpen) {
      const pathname = location.pathname;
      if (pathname === '/app/dashboard') {
        setActiveSettings('dashboard');
      } else if (pathname === '/app/sites/my-site') {
        setActiveSettings('website');
      } else if (pathname === '/app/inbox') {
        setActiveSettings('inbox');
      } else if (pathname === '/app/enroll/leads') {
        setActiveSettings('leads');
      } else if (pathname === '/app/enroll/tours') {
        setActiveSettings('tours');
      } else if (pathname === '/app/families') {
        setActiveSettings('families');
      } else if (pathname === '/app/registration/enrollment') {
        setActiveSettings('enrollment');
      } else if (pathname === '/app/registration/registration') {
        setActiveSettings('registration');
      } else {
        setActiveSettings('dashboard');
      }
    }
  }, [isAutoRedirectToTab, isOpen, location]);

  useEffect(() => {
    if (selectedSettings) {
      setActiveSettings(selectedSettings);
    }
  }, [selectedSettings]);

  useEffect(() => {
    if (selectedSettings) {
      sliceDispatch(getTourSettings());
    }
  }, [selectedSettings, sliceDispatch]);

  const getSettingsInfo = useCallback(async () => {
    // const tourDetails = await sliceDispatch(getTourSettings());
    setTourSettings(tourMeetingDetails);
  }, [tourMeetingDetails]);

  const getProviderSettings = useCallback(() => {
    if (providerId) {
      providerSettingsService.getByProviderId(providerId).then(response => {
        if (response.data) {
          setProviderSettings(response.data);
        } else {
          setProviderSettings({
            onlineRegistrations: false,
            registrationPayments: false,
            autoRegistrationApproval: false,
            archivedLeadsDays: null
          });
        }
      });
    }
  }, [providerId]);

  const getProviderInboxSetting = useCallback(() => {
    providerSettingsService.getProviderInboxSetting().then((response) => {
      setInboxSettings(response.data);
      sliceDispatch(getInboxSetting(response.data));
    });
  }, [sliceDispatch, setInboxSettings]);

  const handleCancel = useCallback(() => {
    onClose();
    setIsDirty(false);
    if (activeSettings === 'registration') {
      getProviderSettings();
    } else if (activeSettings === 'inbox') {
      getProviderInboxSetting();
    }
  }, [onClose, activeSettings, getProviderSettings, getProviderInboxSetting]);

  useEffect(() => {
    if (isMountedRef && providerId) {
      getProviderSettings();
    }
  }, [getProviderSettings, isMountedRef, providerId]);

  useEffect(() => {
    if (isMountedRef) {
      getSettingsInfo();
      getProviderInboxSetting();
    }
  }, [getSettingsInfo, getProviderInboxSetting, isMountedRef]);

  const handleRegistrationSettingsSave = useCallback(() => {
    if (providerSettings.id) {
      providerSettingsService
        .update(providerSettings.id, {
          providerId,
          onlineRegistrations: providerSettings.onlineRegistrations,
          registrationPayments: providerSettings.registrationPayments,
          autoRegistrationApproval: providerSettings.autoRegistrationApproval,
          archivedLeadsDays: providerSettings.archivedLeadsDays,
        })
        .then(() => {
          addToast('Registration settings updated successfully.');
        })
        .catch(err => {
          let errors = err.error && err.error.validationErrors;

          if (errors && errors[0].message) {
            addToast(errors[0].message, 'error');
          } else {
            addToast(
              'Some thing went wrong when updating registration settings',
              'error'
            );
          }
        })
        .finally(() => {
          getProviderSettings();
        });
    } else {
      providerSettingsService
        .create({
          providerId,
          onlineRegistrations: providerSettings.onlineRegistrations,
          registrationPayments: providerSettings.registrationPayments,
          autoRegistrationApproval: providerSettings.autoRegistrationApproval,
          archivedLeadsDays: providerSettings.archivedLeadsDays
        })
        .then(() => {
          addToast('Registration settings updated successfully.');
        })
        .catch(err => {
          let errors = err.error && err.error.validationErrors;

          if (errors && errors[0].message) {
            addToast(errors[0].message, 'error');
          } else {
            addToast(
              'Some thing went wrong when updating registration settings',
              'error'
            );
          }
        })
        .finally(() => {
          getProviderSettings();
        });
    }
  }, [addToast, getProviderSettings, providerSettings, providerId]);

  const handleInboxSettingSave = useCallback(() => {
    providerSettingsService.updateProviderInboxSetting(inboxSettings).then((response) => {
      setInboxSettings(response.data);
      addToast('Inbox settings updated successfully.');
      sliceDispatch(getInboxSetting(response.data));
    }).catch(() => {
      addToast(
        'Some thing went wrong when updating inbox settings',
        'error'
      );
    });
  }, [inboxSettings, addToast, sliceDispatch]);

  const handleTourSettingsSave = useCallback(
    (duration, noOfDays, bufferAfterMeeting) => {
      const request = {
        daysInTheFuture: noOfDays,
        duration,
        bufferBeforeMeeting: tourSettings.bufferBeforeMeeting,
        bufferAfterMeeting,
        meetingType: 'Tour',
        mondayHours: tourSettings.mondayHours,
        tuesdayHours: tourSettings.tuesdayHours,
        wednesdayHours: tourSettings.wednesdayHours,
        thursdayHours: tourSettings.thursdayHours,
        fridayHours: tourSettings.fridayHours,
        saturdayHours: tourSettings.saturdayHours,
        sundayHours: tourSettings.sundayHours
      };

      if (tourSettings.id) {
        calendarEventService
          .updateEventsSchedule(tourSettings.id, request)
          .then(() => {
            addToast('Tour settings updated successfully.');
          })
          .catch(err => {
            let errors = err.error && err.error.validationErrors;

            if (errors && errors[0].message) {
              addToast(errors[0].message, 'error');
            } else {
              addToast(
                'Some thing went wrong when updating tour schedules.',
                'error'
              );
            }
          })
          .finally(() => {
            if (getTourSettingsInfo) {
              getTourSettingsInfo();
            }
          });
      }
    },
    [tourSettings, addToast, getTourSettingsInfo]
  );

  const handleSettingsSave = useCallback(() => {
    if (activeSettings === 'tours') {
      handleTourSettingsSave(tourLength, noOfDays, bufferAfterMeeting);
    } else if (activeSettings === 'inbox') {
      handleInboxSettingSave();
    } else {
      handleRegistrationSettingsSave();
    }
    setIsDirty(false);
    onClose();
  }, [
    tourLength,
    noOfDays,
    bufferAfterMeeting,
    onClose,
    handleTourSettingsSave,
    activeSettings,
    handleInboxSettingSave,
    handleRegistrationSettingsSave
  ]);

  const handleSettingsChange = useCallback(selectedType => {
    setActiveSettings(selectedType);
  }, []);

  const handleIndboxSettingChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    if (name) {
      setInboxSettings((prevState) => ({
        ...prevState,
        [name]: Number(value)
      }));
      setIsDirty(true);
    }
  }, [setInboxSettings, setIsDirty]);

  useEffect(() => {
    if (isMountedRef && tourSettings) {
      setTourLength(tourSettings.duration);
      setNoOfDays(tourSettings.daysInTheFuture);
      setBufferAfterMeeting(tourSettings.bufferAfterMeeting);
    }
  }, [tourSettings, isMountedRef]);

  const handleTooltipClose = useCallback(() => {
    setTooltip(false);
    localStorage.setItem('showTooltip', 'true');
  }, [setTooltip]);

  useEffect(() => {
    if (activeSettings === 'tours') {
      if (noOfDays && tourLength) {
        setIsDirty(true);
      }
    } else if (activeSettings === 'registration') {
    }
  }, [activeSettings, noOfDays, tourLength]);

  return (
    <Dialog
      fullScreen
      onClose={handleCancel}
      open={isOpen}
      className={classes.root}
      classes={{ paperFullScreen: classes.paperFullScreen }}
    >
      <DialogContent classes={{ root: classes.noPadding }}>
        <Box width="100%" height="100%" display="flex">
          <Box borderRight="1px solid #DCDCDC" width="300px" p={4}>
            <Typography className={classes.dialogHeading}>Settings</Typography>
            {/* <Paper component="form" className={classes.searchContainer}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.searchInput}
                placeholder="Search settings"
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="voice"
              >
                <MicIcon />
              </IconButton>
            </Paper> */}
            <Typography className={classes.navHeading}>General</Typography>
            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'dashboard'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('dashboard')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/home.svg" alt="error" />
                  &nbsp;&nbsp;Dashboard
                </ButtonBase>
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'profile'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('profile')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/leads.svg" alt="error" />
                  &nbsp;&nbsp;Profile
                </ButtonBase>
              </ListItem>
            </List>
            <Typography className={classes.navHeading}>Promote</Typography>
            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'website'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('website')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/microsite.svg" alt="error" />
                  &nbsp;&nbsp;Website
                </ButtonBase>
              </ListItem>
            </List>
            <Typography className={classes.navHeading}>Engage</Typography>
            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'inbox'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('inbox')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/chat.svg" alt="error" />
                  &nbsp;&nbsp;Inbox
                </ButtonBase>
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'leads'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('leads')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/leads.svg" alt="error" />
                  &nbsp;&nbsp;Leads
                </ButtonBase>
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'tours'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('tours')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/tours.svg" alt="error" />
                  &nbsp;&nbsp;Tours
                </ButtonBase>
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'families'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('families')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/families.svg" alt="error" />
                  &nbsp;&nbsp;Families
                </ButtonBase>
              </ListItem>
            </List>
            <Typography className={classes.navHeading}>Grow</Typography>
            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'enrollment'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('enrollment')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/child.svg" alt="error" />
                  &nbsp;&nbsp;Enrollment
                </ButtonBase>
              </ListItem>
            </List>
            <ListItem
              className={
                activeSettings === 'registration'
                  ? classes.navItem
                  : classes.navItemUnselected
              }
            >
              <ButtonBase
                onClick={() => handleSettingsChange('registration')}
                className={classes.linkButton}
              >
                <img src="static/icons/registration.svg" alt="error" />
                &nbsp;&nbsp;Registration
              </ButtonBase>
            </ListItem>
          </Box>
          <Box p={4} width="calc(100% - 300px)" position="relative">
            <CloseIcon className={classes.closeIcon} onClick={onClose} />

            {activeSettings === 'dashboard' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Dashboard settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  dashboard
                </Box>
              </>
            )}

            {activeSettings === 'profile' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Center operating times
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <Typography className={classes.settingTitle}>
                    What days of the week is your center open?
                  </Typography>
                  <Box marginBottom="2rem">
                    {daysOfWeek.map((days) => <DayButton title={days.title} checked={days.isChecked} onClick={() => { }} />)}
                  </Box>
                  <Typography className={classes.settingTitle}>
                    What is the earliest opening time and latest closing time of
                    your center each week?
                  </Typography>
                  <Box>
                    <KeyboardTimePicker
                      inputVariant="outlined"
                      variant="inline"
                      label="Opening time"
                      value={selectedStartTime}
                      onChange={handleStartTimeChange}
                      keyboardIcon={<AccessTimeIcon />}
                      className={classes.startTime}
                    />
                    <KeyboardTimePicker
                      inputVariant="outlined"
                      variant="inline"
                      label="Closing time"
                      value={selectedCloseTime}
                      onChange={handleCloseTimeChange}
                      keyboardIcon={<AccessTimeIcon />}
                      className={classes.endTime}
                    />
                  </Box>
                </Box>
              </>
            )}

            {activeSettings === 'website' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Website settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  website
                </Box>
              </>
            )}

            {activeSettings === 'inbox' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Inbox defaults:
                </Typography>
                <Paper elevation={0} className={classes.paperContainer}>
                  {showTooltip && !localStorage.getItem('showTooltip') && (
                    <Box className={classes.tooltipContainer}>
                      <Box className={classes.tooltipIconContainer}>
                        <BulbIcon className={classes.tooltipIcon} />
                      </Box>
                      <Typography className={classes.tooltipText}>Did you know, you can override these defaults every time you send a message to give you complete control over how you send a message.</Typography>
                      <Box className={classes.tooltipButtonContainer}>
                        <IconButton className={classes.tooltipClose} onClick={handleTooltipClose}>
                          <CloseIcon className={classes.tooltipCloseIcon} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                  <Typography variant='h4' className={classes.primaryText}>What is the default way you would like to begin a message you create?</Typography>
                  <RadioGroup
                    name="defaultCreationMessageType"
                    value={inboxSettings?.defaultCreationMessageType}
                    onChange={handleIndboxSettingChange}
                    className={classes.radioGroupRoot}
                  >
                    <FormControlLabel
                      className={classes.radioLabelRoot}
                      value={0}
                      control={<Radio classes={{ root: classes.radioRoot, colorSecondary: classes.colorSecondary, checked: classes.checked }} />}
                      label={
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <Typography className={classes.primaryLabel} component="span">Email</Typography>
                          <Typography className={classes.secondaryLabel} component="span">You can add a subject line</Typography>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio classes={{ root: classes.radioRoot, colorSecondary: classes.colorSecondary, checked: classes.checked }} />}
                      label={
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <Typography className={classes.primaryLabel} component="span">Chat</Typography>
                          <Typography className={classes.secondaryLabel} component="span">Will send an email to begin a chat session</Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </Paper>
                <Typography className={classes.dialogHeading}>
                  Inbox layout options:
                </Typography>
                <Paper elevation={0} className={classes.paperContainer}>
                  <RadioGroup
                    name="inboxLayoutMessageCardType"
                    value={inboxSettings?.inboxLayoutMessageCardType}
                    onChange={handleIndboxSettingChange}
                    className={classes.radioGroupRoot}
                  >
                    <Box>
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.primaryLabel,

                        }}
                        value={0}
                        control={<Radio classes={{ root: classes.radioRoot, colorSecondary: classes.colorSecondary, checked: classes.checked }} />}
                        label="Large message cards"
                      />
                      <MessageBox largeMessage />
                    </Box>
                    <Box>
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.primaryLabel
                        }}
                        value={1}
                        control={<Radio classes={{ root: classes.radioRoot, colorSecondary: classes.colorSecondary, checked: classes.checked }} />}
                        label="Small message cards"
                      />
                      <MessageBox />
                    </Box>
                  </RadioGroup>
                </Paper>
              </>
            )}

            {activeSettings === 'leads' && (
              <>
                <Typography className={classes.dialogHeading}>
                  General lead settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <Box>
                    <Typography className={classes.settingTitle}>
                      After archiving a lead, permanently delete the lead after{' '}
                      <OutlinedInput
                        className={classes.daysInput}
                        id="outlined-days"
                        value={providerSettings.archivedLeadsDays}
                        onChange={(e) => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            archivedLeadsDays: e.target.value
                          }));
                          setIsDirty(true);
                        }}
                      />{' '}
                      days
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {activeSettings === 'tours' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Tour configuration
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <Box mb={6.5}>
                    <Typography className={classes.textContent}>
                      What is the default length of each tour (you can adjust on
                      an individual basis)?
                    </Typography>
                    <SelectWithLabel
                      options={tourLengthOptions}
                      label="Default tour length"
                      id="default-tour-length"
                      value={tourLength}
                      setOnChange={setTourLength}
                    />
                  </Box>
                  <Box mb={6.5}>
                    <Typography className={classes.textContent}>
                      When booking a block of time for a series of tours, how
                      much of a gap do you want between tours?
                    </Typography>
                    <SelectWithLabel
                      options={tourGapOptions}
                      label="Tour gap"
                      id="tour-gap"
                      value={bufferAfterMeeting}
                      setOnChange={setBufferAfterMeeting}
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.textContent}>
                      How many weeks in advance do you want parents to be able
                      to book tours?
                    </Typography>
                    <SelectWithLabel
                      options={weekOptions}
                      label="Schedule limit"
                      id="schedule-limit"
                      value={noOfDays}
                      setOnChange={setNoOfDays}
                    />
                  </Box>
                </Box>
              </>
            )}

            {activeSettings === 'families' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Families settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  families
                </Box>
              </>
            )}

            {activeSettings === 'enrollment' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Enrollment settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  enrollment
                </Box>
              </>
            )}

            {activeSettings === 'registration' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Registration settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={6.5}
                  >
                    <Typography className={classes.textContent}>
                      Allow parents to register online
                    </Typography>

                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.onlineRegistrations}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            onlineRegistrations: !prevSetting.onlineRegistrations
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={6.5}
                  >
                    <Typography className={classes.textContent}>
                      Enable payments during registration
                    </Typography>
                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.registrationPayments}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            registrationPayments: !prevSetting.registrationPayments
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.textContent}>
                      Automatically approve new registrations
                    </Typography>
                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.autoRegistrationApproval}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            autoRegistrationApproval: !prevSetting.autoRegistrationApproval
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions classes={{ root: classes.noPadding }}>
        <div className={classes.saveContainer}>
          <div className={classes.rightSection}>
            <Button
              className={classes.saveButtonContainer}
              onClick={handleSettingsSave}
              variant="contained"
              disabled={!isDirty}
            >
              Save
            </Button>
            <Button
              className={classes.discardButtonContainer}
              onClick={handleCancel}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
