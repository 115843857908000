import React, {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'src/store';
import { apiConfig } from 'src/config';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { pushNotification } from 'src/slices/dashboard';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { UserProviderService } from 'shared';
import { AuthService } from 'src/lib/authService';

const authService = AuthService.getInstance();

interface SignalRProviderProps {
  children?: ReactNode;
}

const userProviderService = UserProviderService.getInstance<
  UserProviderService
>();

const SignalRContext = createContext(null);

export const SignalRProvider: FC<SignalRProviderProps> = ({ children }) => {
  const isMountedRef = useIsMountedRef();
  const [connection, setConnection] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMountedRef.current) {
      const apiUrl = apiConfig.apiUrl.endsWith('/')
        ? apiConfig.apiUrl
        : `${apiConfig.apiUrl}/`;
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}hubs/notification`)
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [isMountedRef]);

  useEffect(() => {
    async function signinAsync() {
      try {
        const user = await authService.getUser();
        if (connection) {
          if (user && user?.access_token) {
            userProviderService.getCurrentProvider().then(({ data }) => {
              connection
                .start()
                .then(() => {
                  console.log('Notification Connected!');
                  connection.on(`ReceiveNotification:${data}`, message => {
                    console.log('ReceiveMessage');
                    dispatch(pushNotification(message));
                  });
                })
                .catch(e => console.log('Connection failed: ', e));
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    signinAsync();
  }, [connection, dispatch]);

  return (
    <SignalRContext.Provider value={{}}>{children}</SignalRContext.Provider>
  );
};

export const SignalRContextConsumer = SignalRContext.Consumer;

export default SignalRProvider;
