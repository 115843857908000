import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Link,
  makeStyles,
  colors,
  Container
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { oidcConfig } from 'src/config';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.common.white,
    boxShadow: 'none',
  },
  toolbar: {
    height: 64,
    backgroundColor: 'transparent',
  },
  logo: {
    marginRight: theme.spacing(2),
    maxWidth: 200,
    maxHeight: 64,
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  getStartedButton: {
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Container maxWidth={false}>
        <Toolbar className={classes.toolbar}>
          <RouterLink to="/">
            <Logo
              className={classes.logo}
            />
          </RouterLink>
          <Box flexGrow={1} />
          <Button
            color="secondary"
            component="a"
            href={`${oidcConfig.stsAuthority}Account/Register?ReturnUrl=${oidcConfig.clientRoot}#/app`}
            variant="contained"
            size="small"
            className={classes.getStartedButton}
          >
            Get Started
        </Button>
          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            Login
        </Link>
        </Toolbar>

      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
