import moment from "moment";
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
    TaskService,
    NotificationService,
    ProviderSummaryService,
    PipelineService,
    IPipelineStage,
    IProviderSummary,
    ITask,
    INotificationByProvider,
    IDashboardEnrollment,
    MicrositeActivityService,
    IMicrositeActivity,
    INotification,
    TaskStatusEnum,
    TaskAssociationEnum
} from 'shared';
import { size, find, filter, sortBy, chain, findIndex, cloneDeep } from "lodash";

interface DashboardState {
    isLoaded: boolean;
    isProfileDialog: boolean;
    isLogoCollectionDialog: boolean;
    enrollment: IDashboardEnrollment;
    microsite: IMicrositeActivity;
    providerSummary: IProviderSummary;
    setupTasks: {
        items: ITask[],
        totalCount: number;
    };
    currentTask: ITask;
    notifications: INotificationByProvider,
    unreadNotification: number;
};

const initialState: DashboardState = {
    isLoaded: false,
    isProfileDialog: false,
    isLogoCollectionDialog: false,
    enrollment: {
        interest: {
            count: 0,
            newRecords: 0
        },
        scheduled: {
            count: 0,
            newRecords: 0
        },
        considering: {
            count: 0,
            newRecords: 0
        },
        registered: {
            count: 0,
            newRecords: 0
        },
        enrolled: {
            count: 0,
            newRecords: 0
        }
    },
    microsite: {
        items: [],
        totalCount: 0,
    },
    providerSummary: {
        providerId: null,
        unreadMessagesCount: 0,
        updateToursStatus: [],
        updateToursStatusCount: 0,
        todayTours: [],
        todayToursCount: 0,
    },
    setupTasks: {
        items: [],
        totalCount: 0
    },
    currentTask: null,
    notifications: {
        items: null,
        totalCount: 0
    },
    unreadNotification: 0
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSetupTasks(state: DashboardState, action: PayloadAction<{ setupTasks: { items: ITask[], totalCount: number } }>) {
            state.setupTasks = action.payload.setupTasks;
        },
        setCurrentTask(state: DashboardState, action: PayloadAction<{ task: ITask }>) {
            state.currentTask = action.payload.task;
        },
        setProfileDialog(state: DashboardState, action: PayloadAction<boolean>) {
            state.isProfileDialog = action.payload;
        },
        setLogoCollectionDialog(state: DashboardState, action: PayloadAction<boolean>) {
            state.isLogoCollectionDialog = action.payload;
        },
        getEnrollmentCount(state: DashboardState, action: PayloadAction<{ enrollment: IDashboardEnrollment }>) {
            state.enrollment = action.payload.enrollment;
        },
        getMicrositeActivity(state: DashboardState, action: PayloadAction<{ microsite: IMicrositeActivity }>) {
            state.microsite = action.payload.microsite;
        },
        getProviderSummary(state: DashboardState, action: PayloadAction<{ providerSummary: IProviderSummary }>) {
            state.providerSummary = action.payload.providerSummary;
        },
        getNotifications(state: DashboardState, action: PayloadAction<{ notifications: INotificationByProvider }>) {
            state.notifications = action.payload.notifications;
            state.unreadNotification = action.payload.notifications.items.filter(item => item.status < 1).length || 0;
        }
    }
});

export const reducer = slice.reducer;

const pipelineService = PipelineService.getInstance<PipelineService>();
const micrositeActivityService = MicrositeActivityService.getInstance<MicrositeActivityService>();
const providerSummaryService = ProviderSummaryService.getInstance<ProviderSummaryService>();
const taskService = TaskService.getInstance<TaskService>();
const notificationService = NotificationService.getInstance<NotificationService>();

export const getEnrollmentCount = (): AppThunk => (dispatch) => {
    return pipelineService.getWithDetails({
        PipelineType: 'Lead',
        PipelineRelationType: 7,
    }).then((response) => {
        const lists: IPipelineStage[] = response.data.pipelineStages;
        const getInterestData = find(lists, (item) => item.name === "Early Interest");
        const getScheduledData = find(lists, (item) => item.name === "Tour Scheduled");
        const getConsideringData = find(lists, (item) => item.name === "Considering");
        const getRegisteredData = find(lists, (item) => item.name === "Registered");
        const getEnrolledData = find(lists, (item) => item.name === "Enrolled");
        const enrollment = {
            interest: {
                count: (getInterestData && size(getInterestData.deals)) || 0,
                newRecords: (getInterestData && size(filter(getInterestData.deals, (item) => moment(item.creationTime).format("DD") === moment().format("DD")))) || 0
            },
            scheduled: {
                count: (getScheduledData && size(getScheduledData.deals)) || 0,
                newRecords: (getScheduledData && size(filter(getScheduledData.deals, (item) => moment(item.creationTime).format("DD") === moment().format("DD")))) || 0
            },
            considering: {
                count: (getConsideringData && size(getConsideringData.deals)) || 0,
                newRecords: (getConsideringData && size(filter(getConsideringData.deals, (item) => moment(item.creationTime).format("DD") === moment().format("DD")))) || 0
            },
            registered: {
                count: (getRegisteredData && size(getRegisteredData.deals)) || 0,
                newRecords: (getRegisteredData && size(filter(getRegisteredData.deals, (item) => moment(item.creationTime).format("DD") === moment().format("DD")))) || 0
            },
            enrolled: {
                count: (getEnrolledData && size(getEnrolledData.deals)) || 0,
                newRecords: (getEnrolledData && size(filter(getEnrolledData.deals, (item) => moment(item.creationTime).format("DD") === moment().format("DD")))) || 0
            }
        }
        dispatch(slice.actions.getEnrollmentCount({ enrollment }));
    });
};

export const getMicrositeActivity = (): AppThunk => (dispatch) => {
    return micrositeActivityService.get().then((response) => {
        const microsite = response.data;
        dispatch(slice.actions.getMicrositeActivity({ microsite }));
    })
};

export const getProviderSummary = (): AppThunk => (dispatch) => {
    return providerSummaryService.get().then((response) => {
        const providerSummary = response.data;
        dispatch(slice.actions.getProviderSummary({ providerSummary }));
    })
}

export const getSetupTasks = (forceFetch = false): AppThunk => async (dispatch, getState) => {
    const { setupTasks } = getState().dashboard;
    if (forceFetch || !(setupTasks?.items?.length > 0)) {
        const data = await taskService.getTasksByProvider().then(({ data }) => {
            data.items = updateTaskOrder(data.items)
            const task = getCurrentTask(data.items);
            dispatch(slice.actions.setCurrentTask({ task }));
            dispatch(slice.actions.setSetupTasks({ setupTasks: data }));
            return data
        });
        return data;
    } else {
        return setupTasks;
    }
}

export const addTask = (data): AppThunk => (dispatch, getState) => {
    const { setupTasks } = getState().dashboard;
    let items = setupTasks.items.concat(data);
    items = updateTaskOrder(items)
    const task = getCurrentTask(items);
    dispatch(slice.actions.setCurrentTask({ task }));
    dispatch(slice.actions.setSetupTasks({
        setupTasks: {
            items,
            totalCount: setupTasks.totalCount + data.length
        }
    }));
}

export const makeTaskDone = (item: ITask, status = TaskStatusEnum.Done): AppThunk => (dispatch, getState) => {
    const { setupTasks } = getState().dashboard;
    return taskService.update(item.id, {
        ...item,
        status,
    })
        .then(({ data }) => {
            const items = cloneDeep(setupTasks.items);
            const index = findIndex(setupTasks.items, { id: data.id });
            items.splice(index, 1, data);
            const task = getCurrentTask(items);
            dispatch(slice.actions.setCurrentTask({ task }));
            dispatch(slice.actions.setSetupTasks({ setupTasks: { ...setupTasks, items } }));
            return setupTasks
        });
}

export const getNotifications = (): AppThunk => (dispatch) => {
    return notificationService.getNotificationsByProvider().then((response) => {
        const notifications = response.data;
        dispatch(slice.actions.getNotifications({ notifications }));
    });
}

export const setNotifications = (notifications: INotificationByProvider): AppThunk => (dispatch) => {
    dispatch(slice.actions.getNotifications({ notifications }));
}

export const pushNotification = (notification: INotification): AppThunk => (dispatch, getState) => {
    const { notifications } = getState().dashboard;
    const items: INotification[] = [notification, ...(notifications?.items || [])];
    dispatch(slice.actions.getNotifications({
        notifications: {
            items,
            totalCount: (notifications.totalCount || 0) + 1,
        }
    }));
}

export const markAsReadNotification = (notificationsId: string[]): AppThunk => (dispatch, getState) => {
    const { notifications } = getState().dashboard;
    const items: INotification[] = notifications.items.map((item) => {
        if (notificationsId.includes(item.id)) {
            return {
                ...item,
                status: 1
            }
        }
        return item;
    });
    dispatch(slice.actions.getNotifications({
        notifications: {
            items,
            totalCount: notifications.totalCount,
        }
    }))
    return notificationService.markAsRead({ notificationsId });
};

export const deleteNotification = (notificationsId: string): AppThunk => (dispatch, getState) => {
    const { notifications } = getState().dashboard;
    const items = cloneDeep(notifications.items);
    const index = findIndex(items, { id: notificationsId });
    items.splice(index, 1);

    dispatch(slice.actions.getNotifications({
        notifications: {
            items,
            totalCount: notifications.totalCount - 1,
        }
    }))
    return notificationService.delete(notificationsId);
};

export const setProfileDialog = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch(slice.actions.setProfileDialog(value));
    }
}

export const setLogoCollectionDialog = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch(slice.actions.setLogoCollectionDialog(value));
    }
}

export default slice;


function updateTaskOrder(items: ITask[]) {
    const order = [
        TaskAssociationEnum.PROFILE,
        TaskAssociationEnum.MICROSITE,
    ];
    return sortBy(items, (task) => order.indexOf(task.association));
}

function getCurrentTask(item: ITask[]) {
    return chain(item).filter({ status: TaskStatusEnum.NotDone }).first().value();
}