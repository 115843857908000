import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SetupAccountLayout from './layouts/SetupAccountLayout';
import PortalLayout from './layouts/PortalLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    // callback for OIDC login
    exact: true,
    path: '/signin-oidc',
    component: lazy(() => import('src/views/auth/SigninOidc'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/setup-account',
    guard: AuthGuard,
    layout: SetupAccountLayout,
    routes: [
      {
        exact: true,
        path: '/setup-account',
        component: () => <Redirect to="/setup-account/welcome" />
      },
      {
        exact: true,
        path: '/setup-account/welcome',
        component: lazy(() => import('src/pages/setup-account/WelcomeView'))
      },
      {
        exact: true,
        path: '/setup-account/basic-info',
        component: lazy(() => import('src/pages/setup-account/BasicInfoView'))
      },

      {
        exact: true,
        path: '/setup-account/tours',
        component: lazy(() => import('src/pages/setup-account/ToursView'))
      },
      {
        exact: true,
        path: '/setup-account/programs',
        component: lazy(() => import('src/pages/setup-account/ProgramsView'))
      },
      {
        exact: true,
        path: '/setup-account/micro-site',
        component: lazy(() => import('src/pages/setup-account/MicroSiteView'))
      },
      {
        exact: true,
        path: '/setup-account/microsite-setup',
        component: lazy(() =>
          import('src/pages/setup-account/MicrositeSetupView')
        )
      },
      {
        exact: true,
        path: '/setup-account/success',
        component: lazy(() =>
          import('src/pages/setup-account/FinalOnboardingView')
        )
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: PortalLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/pages/dashboard/DashboardView'))
      },

      {
        exact: true,
        path: '/app/school/virtual-tours',
        component: lazy(() => import('src/pages/block/VirtualToursView'))
      },
      {
        exact: true,
        path: '/app/school/my-center',
        component: () => <Redirect to="/school/my-center/basic" />
      },
      {
        exact: true,
        path: '/app/school/my-center/basic',
        component: lazy(() => import('src/pages/account/my-center/BasicView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/contact',
        component: lazy(() => import('src/pages/account/my-center/ContactView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/programs',
        component: lazy(() =>
          import('src/pages/account/my-center/ProgramsView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/schedule',
        component: lazy(() =>
          import('src/pages/account/my-center/ScheduleView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/state',
        component: lazy(() => import('src/pages/account/my-center/StateView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/age-group',
        component: lazy(() =>
          import('src/pages/account/my-center/AgeGroupView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/tour-settings',
        component: lazy(() => import('src/pages/tours/TourSettingView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/templates',
        component: lazy(() =>
          import('src/pages/account/my-center/TemplatesView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/EmailTemplateListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/my-templates',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-schedule',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentScheduleView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-sessions',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentSessionView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-classes',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentClassesView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-class-options',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentClassOptionsView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-room',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentRoomView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-price',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentPriceView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates/edit/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/EditEmailTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/template/create',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/template/edit/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates/view/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/ViewEmailTemplateView'
          )
        )
      },

      {
        exact: true,
        path: '/app/enroll',
        component: () => <Redirect to="/app/enroll/leads" />
      },
      {
        exact: true,
        path: '/app/enroll/leads',
        component: lazy(() => import('src/pages/enroll/LeadsView'))
      },
      {
        exact: true,
        path: '/app/enroll/tours',
        component: lazy(() => import('src/pages/tours/ToursView'))
      },
      {
        exact: true,
        path: '/app/enroll/programs',
        component: lazy(() =>
          import('src/pages/enroll/programs/ProgramsListView')
        )
      },
      {
        exact: true,
        path: '/app/enroll/programs/view/:id',
        component: lazy(() =>
          import('src/pages/enroll/programs/ProgramsDetailView')
        )
      },
      {
        exact: true,
        path: '/app/families',
        component: lazy(() => import('src/pages/families/FamiliesView'))
      },
      {
        exact: true,
        path: '/app/families/view/:id',
        component: lazy(() => import('src/pages/families/FamilyDetailsView'))
      },
      {
        exact: true,
        path: '/app/sites',
        component: () => <Redirect to="/app/my-center" />
      },
      {
        exact: true,
        path: '/app/sites/my-site/virtual-tours',
        component: lazy(() => import('src/pages/block/VirtualToursView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site-setting',
        component: lazy(() => import('src/pages/my-site-setting'))
      },
      {
        exact: true,
        path: '/app/sites/my-site',
        component: lazy(() => import('src/pages/my-site/MySiteView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/configure',
        component: lazy(() => import('src/pages/my-site/ConfigureView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/new-block',
        component: lazy(() => import('src/pages/my-site/NewBlockView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/my-block',
        component: lazy(() => import('src/pages/my-site/BlocksView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/programs',
        component: lazy(() => import('src/pages/my-site/ProgramsView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/day-in-life',
        component: lazy(() => import('src/pages/my-site/DayInLifeView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/covid-19',
        component: lazy(() => import('src/pages/my-site/CovidView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/welcome-letter',
        component: lazy(() => import('src/pages/my-site/AboutUsView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/day-in-life/timeline',
        component: lazy(() =>
          import('src/pages/my-site/DayInLifeView/TimelineView')
        )
      },
      {
        exact: true,
        path: '/app/sites/my-site/slideshows',
        component: lazy(() =>
          import('src/pages/my-site/slideshows/SlideshowsView')
        )
      },
      {
        exact: true,
        path: '/app/blocks',
        component: () => <Redirect to="/app/blocks/virtual-tour" />
      },
      {
        exact: true,
        path: '/app/blocks/slides',
        component: lazy(() => import('src/pages/blocks/SlidesView'))
      },
      {
        exact: true,
        path: '/app/blocks/programs',
        component: lazy(() => import('src/pages/blocks/ProgramsView'))
      },
      {
        exact: true,
        path: '/app/blocks/virtual-tour',
        component: lazy(() => import('src/pages/blocks/VirtualTourView'))
      },
      {
        exact: true,
        path: '/app/inbox',
        component: lazy(() => import('src/pages/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/inbox/:threadKey',
        component: lazy(() => import('src/pages/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/inbox/chatbot/settings',
        component: lazy(() => import('src/pages/chat/ChatBotSettingView'))
      },
      {
        exact: true,
        path: '/app/inbox/chatbot/questions',
        component: lazy(() => import('src/pages/chat/ChatBotQuestionView'))
      },
      {
        exact: true,
        path: '/app/features',
        component: () => <Redirect to="/app/features/chat" />
      },
      {
        exact: true,
        path: '/app/features/chat',
        component: lazy(() => import('src/pages/features/ChatView'))
      },
      {
        exact: true,
        path: '/app/features/meetings',
        component: lazy(() => import('src/pages/features/MeetingsView'))
      },
      {
        exact: true,
        path: '/app/features/register',
        component: lazy(() => import('src/pages/features/RegisterView'))
      },
      {
        exact: true,
        path: '/app/my-center',
        component: () => <Redirect to="/school/my-center/basic" />
      },
      {
        exact: true,
        path: '/app/library',
        component: lazy(() => import('src/pages/library/LibraryView'))
      },
      {
        exact: true,
        path: '/app/library/folder/:id',
        component: lazy(() => import('src/pages/library/LibraryView'))
      },
      {
        exact: true,
        path: '/app/content',
        component: () => <Redirect to="/app/stories/my-stories" />
      },
      {
        exact: true,
        path: '/app/content/slides',
        component: lazy(() => import('src/pages/content/slides/SlideView'))
      },
      {
        exact: true,
        path: '/app/content/slides/create',
        component: lazy(() => import('src/pages/content/slides/NewSlideView'))
      },
      {
        exact: true,
        path: '/app/content/slides/edit/:id',
        component: lazy(() => import('src/pages/content/slides/EditSlideView'))
      },
      {
        exact: true,
        path: '/app/content/learn',
        component: lazy(() => import('src/pages/content/LearnView'))
      },
      {
        exact: true,
        path: '/app/stories/create',
        component: lazy(() =>
          import('src/pages/account/stories/new-story/NewStoryView')
        )
      },
      {
        exact: true,
        path: '/app/stories/edit/:id',
        component: lazy(() =>
          import('src/pages/account/stories/new-story/EditStoryView')
        )
      },
      {
        exact: true,
        path: '/app/stories/my-stories',
        component: lazy(() =>
          import('src/pages/account/stories/my-stories/MyStoriesView')
        )
      },
      {
        exact: true,
        path: '/app/stories/inspiration',
        component: lazy(() => import('src/pages/account/stories/inspiration'))
      },
      {
        exact: true,
        path: '/app/stories/learning',
        component: lazy(() => import('src/pages/account/stories/learning'))
      },

      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('src/pages/settings/SettingsView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/profile',
        component: () => <Redirect to="/app/profile/general" />
      },
      {
        exact: true,
        path: '/app/profile/general',
        component: lazy(() => import('src/pages/account/profile/GeneralView'))
      },
      {
        exact: true,
        path: '/app/profile/subscription',
        component: lazy(() =>
          import('src/pages/account/profile/SubscriptionView')
        )
      },
      {
        exact: true,
        path: '/app/profile/notification',
        component: lazy(() =>
          import('src/pages/account/profile/NotificationView')
        )
      },
      {
        exact: true,
        path: '/app/profile/security',
        component: lazy(() => import('src/pages/account/profile/SecurityView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      // {
      //   exact: true,
      //   path: '/app/chat',
      //   component: () => <Redirect to="/app/chat/new" />
      // },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: true,
        path: '/app/programs',
        component: lazy(() => import('src/pages/programs/ProgramsView'))
      },
      {
        exact: true,
        path: '/app/programs/classes',
        component: lazy(() => import('src/pages/programs/ProgramsList'))
      },
      {
        exact: true,
        path: '/app/programs/marketing',
        component: lazy(() => import('src/pages/programs/MarketingView'))
      },
      {
        exact: true,
        path: '/app/my-profile',
        component: () => <Redirect to="/app/my-profile/general" />
      },
      {
        exact: true,
        path: '/app/my-profile/general',
        component: lazy(() =>
          import('src/pages/account/my-profile/GeneralView')
        )
      },
      {
        exact: true,
        path: '/app/my-profile/notifications',
        component: lazy(() =>
          import('src/pages/account/my-profile/NotificationsView')
        )
      },
      {
        exact: true,
        path: '/app/my-profile/security',
        component: lazy(() =>
          import('src/pages/account/my-profile/SecurityView')
        )
      },
      {
        exact: true,
        path: '/app/my-organization',
        component: () => <Redirect to="/app/my-organization/subscription" />
      },
      {
        exact: true,
        path: '/app/my-organization/subscription',
        component: lazy(() =>
          import('src/pages/account/my-organization/SubscriptionView')
        )
      },
      {
        exact: true,
        path: '/app/my-organization/subscription/payment',
        component: lazy(() =>
          import('src/pages/account/my-organization/PaymentView')
        )
      },
      {
        exact: true,
        path: '/app/my-organization/centers',
        component: lazy(() =>
          import('src/pages/account/my-organization/CentersView')
        )
      },
      {
        exact: true,
        path: '/app/my-organization/users',
        component: lazy(() =>
          import('src/pages/account/my-organization/UsersView')
        )
      },
      {
        exact: true,
        path: '/app/my-organization/parent-payments',
        component: lazy(() =>
          import('src/pages/account/my-organization/ParentPaymentsView')
        )
      },
      {
        exact: true,
        path: '/app/registration/registration',
        component: lazy(() => import('src/pages/registration/RegistrationView'))
      },
      {
        exact: true,
        path: '/app/registration/enrollment',
        component: lazy(() => import('src/pages/registration/EnrollmentView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: HomeView
      },
      // {
      //   exact: true,
      //   path: '/pricing',
      //   component: lazy(() => import('src/views/pricing/PricingView'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
