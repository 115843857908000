import { createSlice } from '@reduxjs/toolkit';
import { DealService } from 'shared';
import { AppThunk } from 'src/store';

export interface RegistrationState {
  registrations: any;
}

const initialState: RegistrationState = { registrations: null };

const dealService = DealService.getInstance<DealService>();

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistrationList(state: RegistrationState, action: any) {
      state.registrations = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getRegistrationList = (): AppThunk => dispatch => {
  dealService.getRegistrationList().then(({ data }) => {
    dispatch(slice.actions.setRegistrationList(data));
  });
};

export default slice;
