import React, { useState, FC, ReactNode, useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Typography,
  useMediaQuery,
  Button,
  Popover,
  Link
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from './Footer';
import useAuth from 'src/hooks/useAuth';
import { AuthService } from 'src/lib/authService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsDialog from 'src/components/SettingsDialog';

interface HomeLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    '@media (min-width: 1024px)': {
      paddingLeft: 96
    },
    '@media (min-width: 1366px)': {
      paddingLeft: 218
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  menuButton: {
    padding: 0
  },
  mobileWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 72,
    paddingLeft: 40,
    paddingRight: 40,
    background: '#F3FAFA',
    boxShadow: '0px 4px 15px rgba(219, 219, 219, 0.25)'
  },
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    marginRight: theme.spacing(1)
  },
  logoImage: {
    width: 32,
    height: 32,
    borderRadius: '100%'
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  brandName: {
    whiteSpace: 'nowrap',
    fontSize: 20,
    marginRight: 10,
    color: theme.palette.text.secondary
  },
  iconButton: {
    padding: 0,
    width: 24,
    height: 24,
    background: '#F3FAFA',
    borderRadius: 4,
    color: theme.palette.text.secondary
  },
  button: {
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  logoutLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  userImageWrapper: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userImageWrapperTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25
  },
  username: {
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    textAlign: 'left',
    fontWeight: 500,
    fontSize: 14
  },
  usernamePopover: {
    textTransform: 'capitalize',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: 'left',
    paddingLeft: theme.spacing(2)
  },
  userEmail: {
    color: theme.palette.text.secondary,
    fontFamily: '"Source Sans Pro", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  userDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  userDetailsContainerBorder: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #DCDCDC'
  },
  userDetailsContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  userDetailsContentWrapperTop: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerActionIcon: {
    width: '100%',
    height: '100%'
  },
  profileImage: {
    borderRadius: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: 'cover'
  },
  navItemLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const PortalLayout: FC<HomeLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const { provider, profileUser } = useSelector(state => ({
    provider: state['currentUser'].provider,
    profileUser: state['currentUser'].user
  }));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(
    false
  );

  const { user, providerId, logout } = useAuth();
  const authService = AuthService.getInstance();

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsModalClose = useCallback(() => {
    setIsSettingsModalOpen(false);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      window.analytics?.track('Signed Out', {
        user_id: user?.profile?.sub,
        provider_id: providerId
      });
      await authService.logout();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const matches = useMediaQuery('(min-width:1366px)');

  return (
    <div className={classes.root}>
      {matches ? (
        <TopBar />
      ) : (
        <Box className={classes.mobileWrapper}>
          <Box className={classes.logoWrapper}>
            <Box className={classes.logoContainer}>
              <RouterLink to="/app/">
                {provider && provider.logo ? (
                  <img
                    className={classes.logoImage}
                    src={provider.logo}
                    alt="school-logo"
                  />
                ) : (
                  <img
                    className={classes.logoImage}
                    src="/static/images/schoolLogo.png"
                    alt="school-logo"
                  />
                )}
              </RouterLink>
            </Box>
            <Box className={classes.brandContainer}>
              <Typography variant="h5" className={classes.brandName}>
                {provider && provider.name}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box marginRight={2}>
              <Button className={classes.button}>
                <Box className={classes.userDetailsContainer}>
                  <Box className={classes.userImageWrapperTop}>
                    <img
                      src="/static/icons/help.svg"
                      alt="help"
                      className={classes.headerActionIcon}
                    />
                  </Box>
                  <Box
                    paddingTop={0.3}
                    className={classes.userDetailsContentWrapperTop}
                  >
                    <Typography className={classes.username}>Help</Typography>
                  </Box>
                </Box>
              </Button>
            </Box>
            <Box marginRight={2}>
              <Button
                className={classes.button}
                onClick={() => setIsSettingsModalOpen(true)}
              >
                <Box className={classes.userDetailsContainer}>
                  <Box className={classes.userImageWrapperTop}>
                    <img
                      src="/static/icons/settings.svg"
                      alt="settings"
                      className={classes.headerActionIcon}
                    />
                  </Box>
                  <Box
                    paddingTop={0.3}
                    className={classes.userDetailsContentWrapperTop}
                  >
                    <Typography className={classes.username}>
                      Settings
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Box>
            <Box>
              <Button className={classes.button} onClick={handleProfileClick}>
                <Box className={classes.userDetailsContainer}>
                  <Box className={classes.userImageWrapperTop}>
                    {profileUser?.imageUrl ? (
                      <img
                        className={classes.profileImage}
                        src={profileUser?.imageUrl}
                        alt="avatar"
                      />
                    ) : (
                      <AccountCircleIcon fontSize="large" />
                    )}
                  </Box>
                  <Box className={classes.userDetailsContentWrapperTop}>
                    <Typography className={classes.username}>
                      profile
                    </Typography>
                  </Box>
                </Box>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Box
                  paddingY={2}
                  className={classes.userDetailsContainerBorder}
                >
                  <Box className={classes.userDetailsContentWrapper}>
                    <Typography className={classes.usernamePopover}>
                      {profileUser?.name} {profileUser?.surname}
                    </Typography>
                  </Box>
                  <Box className={classes.userImageWrapper}>
                    {profileUser?.imageUrl ? (
                      <>
                        <img
                          className={classes.profileImage}
                          src={profileUser?.imageUrl}
                          alt="avatar"
                        />
                      </>
                    ) : (
                      <>
                        <AccountCircleIcon fontSize="large" />
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  paddingX={2}
                  paddingY={1}
                  borderBottom="1px solid #DCDCDC"
                  width="100%"
                >
                  <Link
                    className={classes.navItemLink}
                    component={RouterLink}
                    to="/app/my-profile"
                  >
                    My Profile
                  </Link>
                </Box>
                <Box
                  paddingX={2}
                  paddingY={1}
                  borderBottom="1px solid #DCDCDC"
                  width="100%"
                >
                  <Link
                    className={classes.navItemLink}
                    component={RouterLink}
                    to="/app/my-organization"
                  >
                    My Organization
                  </Link>
                </Box>
                <Box paddingX={2} paddingY={1} width="100%">
                  <Link className={classes.logoutLink} onClick={handleLogout}>
                    Logout
                  </Link>
                </Box>
              </Popover>
            </Box>
          </Box>
        </Box>
      )}
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Footer />
      <SettingsDialog
        onClose={handleSettingsModalClose}
        isOpen={isSettingsModalOpen}
        selectedSettings="registration"
        isAutoRedirectToTab
      />
    </div>
  );
};

export default PortalLayout;
