import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as leadsReducer } from 'src/slices/leads';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as currentUserReducer } from 'src/slices/current-user';
import { reducer as programsReducer } from 'src/slices/programs';
import { reducer as BasicInfoReducer } from 'src/slices/basic-info';
import { reducer as onBoardingReducer } from 'src/slices/on-boarding';
import { reducer as familiesReducer } from 'src/slices/families';
import { reducer as dashboardReducer } from 'src/slices/dashboard';
import { reducer as mediaReducer } from 'src/slices/media';
import { reducer as featureFlagReducer } from 'src/slices/feature-flags';
import { reducer as tourReducer } from 'src/slices/tour';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as enrollmentReducer } from 'src/slices/enrollment';
import { reducer as registrationReducer } from 'src/slices/registration';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  currentUser: currentUserReducer,
  onBoarding: onBoardingReducer,
  programs: programsReducer,
  kanban: kanbanReducer,
  leads: leadsReducer,
  media: mediaReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  basicInfo: BasicInfoReducer,
  families: familiesReducer,
  dashboard: dashboardReducer,
  featureFlags: featureFlagReducer,
  tour: tourReducer,
  settings: settingsReducer,
  enrollment: enrollmentReducer,
  registration: registrationReducer
});

export default rootReducer;
