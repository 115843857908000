import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Button, Box } from '@material-ui/core';
import { Theme } from 'src/theme';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) => ({
  daysBtn: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: '1px solid #3F7F8D',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    marginRight: '16px',
    '&.checked': {
      backgroundColor: '#3F7F8D',
      color: '#ffffff'
    }
  },
  checked: {},
  focusVisible: {}
}));

export interface DayButtonProps {
  title?: string;
  checked?: boolean;
  onClick?: (e) => void;
}

const DayButton: FC<DayButtonProps> = ({ checked, onClick, title }) => {
  const classes = useStyles();

  return (
    <Button variant="outlined" color="primary" className={clsx(classes.daysBtn, { 'checked': checked })} onClick={() => onClick(title)}>
      <Box width="30px" display="flex" alignItems="center">
        {checked && <CheckIcon />}
      </Box>
      <Typography>{title}</Typography>
    </Button>
  );
};

export default DayButton;
