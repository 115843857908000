export const TourInfoMapper = apiResponse => {
  //Weekly Schedule
  let weeklySchedule = {
    mondayHours: [],
    tuesdayHours: [],
    wednesdayHours: [],
    thursdayHours: [],
    fridayHours: [],
    saturdayHours: [],
    sundayHours: []
  };

  const weeklyScheduleResponse = apiResponse.schedules.filter(
    item => item.dayOfWeek !== null
  );

  //Convert the existing weekly schedule to the UI data format
  if (weeklyScheduleResponse.length > 0) {
    for (let i = 0; i < weeklyScheduleResponse.length; i++) {
      if (weeklyScheduleResponse[i].dayOfWeek === 1) {
        weeklySchedule.mondayHours = DayInfoMapper(
          weeklySchedule.mondayHours,
          weeklyScheduleResponse[i]
        );
      } else if (weeklyScheduleResponse[i].dayOfWeek === 2) {
        weeklySchedule.tuesdayHours = DayInfoMapper(
          weeklySchedule.tuesdayHours,
          weeklyScheduleResponse[i]
        );
      } else if (weeklyScheduleResponse[i].dayOfWeek === 3) {
        weeklySchedule.wednesdayHours = DayInfoMapper(
          weeklySchedule.wednesdayHours,
          weeklyScheduleResponse[i]
        );
      } else if (weeklyScheduleResponse[i].dayOfWeek === 4) {
        weeklySchedule.thursdayHours = DayInfoMapper(
          weeklySchedule.thursdayHours,
          weeklyScheduleResponse[i]
        );
      } else if (weeklyScheduleResponse[i].dayOfWeek === 5) {
        weeklySchedule.fridayHours = DayInfoMapper(
          weeklySchedule.fridayHours,
          weeklyScheduleResponse[i]
        );
      } else if (weeklyScheduleResponse[i].dayOfWeek === 6) {
        weeklySchedule.saturdayHours = DayInfoMapper(
          weeklySchedule.saturdayHours,
          weeklyScheduleResponse[i]
        );
      } else {
        weeklySchedule.sundayHours = DayInfoMapper(
          weeklySchedule.sundayHours,
          weeklyScheduleResponse[i]
        );
      }
    }
  }

  //Events mapping
  const eventsArray = apiResponse.meetings.map((meeting, index) => {
    return {
      id: index + 1,
      backgroundColor: '#dfddf8',
      borderColor: '#dfddf8',
      start: new Date(`${meeting.date} ${meeting.startTime}`),
      end: new Date(`${meeting.date} ${meeting.endTime}`),
      title: `${meeting.startTime} - ${meeting.endTime}`,
      data: {
        ...meeting
      },
      startTimeField: meeting.startTime,
      endTimeField: meeting.endTime,
      meetingLocation: meeting.meetingLocation,
      // startTime: meeting.startTime,
      // endTime: meeting.endTime,
      date: meeting.date
    };
  });

  return {
    id: apiResponse.id,
    duration: apiResponse.duration,
    bufferAfterMeeting: apiResponse.bufferAfterMeeting,
    bufferBeforeMeeting: apiResponse.bufferBeforeMeeting,
    weeksInTheFuture: apiResponse.daysInTheFuture / 7,
    daysInTheFuture: apiResponse.daysInTheFuture,
    // meetingLocation: apiResponse.meetingLocation,
    mondayHours: weeklySchedule.mondayHours,
    tuesdayHours: weeklySchedule.tuesdayHours,
    wednesdayHours: weeklySchedule.wednesdayHours,
    thursdayHours: weeklySchedule.thursdayHours,
    fridayHours: weeklySchedule.fridayHours,
    saturdayHours: weeklySchedule.saturdayHours,
    sundayHours: weeklySchedule.sundayHours,
    events: eventsArray,
    schedules: apiResponse.schedules,
    overlapDates: apiResponse.overlapDates,
    meetings: apiResponse.meetings,
    parents: apiResponse.parents,
    modifiedFields: []
  };
};

const DayInfoMapper = (weeklyDayHours, weeklyScheduleResponse) => {
  return [
    ...weeklyDayHours,
    {
      id: weeklyDayHours.length + 1,
      startTime: weeklyScheduleResponse.startTime
        ?.toLowerCase()
        .replace(/\s/g, '')
        .replace(/^0+/, ''),
      endTime:
        weeklyScheduleResponse.endTime !== null
          ? weeklyScheduleResponse.endTime
              .toLowerCase()
              .replace(/\s/g, '')
              .replace(/^0+/, '')
          : '5:00pm',
      meetingLocation: weeklyScheduleResponse.meetingLocation
    }
  ];
};
