import React, { useState } from 'react';
import { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Theme } from 'src/theme';

interface MobileNavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  info?: any;
  open?: boolean;
  title: string;
  iconPath?: string;
  icon?: any;
  notification?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: theme.spacing(1),
    '&:hover': {
      background: '#F4F4F4',
      fontWeight: 600
    }
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      background: '#F4F4F4',
      fontWeight: 600
    },
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontFamily: '"Montserrat", sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    '&:active': {
      fontWeight: 700
    },
    marginLeft: theme.spacing(2)
  },
  active: {
    background: '#F3FAFA',
    borderRadius: theme.spacing(1),
    '& $title': {
      fontWeight: 700
    }
  },
  icon: {
    display: 'flex',
    width: '1.4em',
    height: '1.4em',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  iconSize: {
    height: 28,
    width: 28
  },
  listRoot: {
    alignItems: 'flex-start'
  },
  fullWidth: {
    width: '100%'
  }
}));

const MobileNavItem: FC<MobileNavItemProps> = ({
  children,
  className,
  depth,
  href,
  info: Info,
  open: openProp,
  title,
  iconPath,
  icon: Icon,
  notification,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
        classes={{ root: classes.listRoot }}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          {iconPath && (
            <img className={classes.iconSize} src={iconPath} alt={title} />
          )}
          {/* <span className={classes.title}>{title}</span> */}
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse className={classes.fullWidth} in={open}>
          <Box marginLeft={2}>{children}</Box>
        </Collapse>
      </ListItem>
    );
  }

  const isExternalLink = () => {
    return !!href.startsWith('http');
  };

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      {!isExternalLink() ? (
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          {iconPath && (
            <img className={classes.iconSize} src={iconPath} alt={title} />
          )}
        </Button>
      ) : (
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component="a"
          style={style}
          href={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          {iconPath && (
            <img className={classes.iconSize} src={iconPath} alt={title} />
          )}
        </Button>
      )}
    </ListItem>
  );
};

export default MobileNavItem;
