
export const OvPrimary = {
  50: '#eaf3f5',
  100: '#cae1e6',
  200: '#a7cdd6',
  300: '#83b8c5',
  400: '#69a9b8',
  500: '#4e9aac',
  600: '#4792a5',
  700: '#3d889b',
  800: '#357e92',
  900: '#256c82',
  A100: '#c3f0ff',
  A200: '#90e3ff',
  A400: '#5dd7ff',
  A700: '#43d1ff',

  light: '#69a9b8',
  main: '#4e9aac',
  dark: '#4792a5',
  contrastText: '#fff',
};



export const OvSecondary = {
  50: '#fdf0ea',
  100: '#fadaca',
  200: '#f7c1a6',
  300: '#f3a882',
  400: '#f19568',
  500: '#ee824d',
  600: '#ec7a46',
  700: '#e96f3d',
  800: '#e76534',
  900: '#e25225',
  A100: '#ffffff',
  A200: '#ffeeea',
  A400: '#ffc6b7',
  A700: '#ffb29d',

  light: '#f19568',
  main: '#ee824d',
  dark: '#ec7a46',
  contrastText: '#fff',
};


export const OvThird = {
  50: '#edebff',
  100: '#d1ceff',
  200: '#b3aeff',
  300: '#958dff',
  400: '#7e74ff',
  500: '#675cff',
  600: '#5f54ff',
  700: '#544aff',
  800: '#4a41ff',
  900: '#3930ff',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#dddbff',
  A700: '#c4c2ff',

  light: '#7e74ff',
  main: '#675cff',
  dark: '#5f54ff',
  contrastText: '#fff',
};

export const OvBlue = {
  50: '#e2eaee',
  100: '#A4B2BE',
  200: '#88a6b8',
  300: '#58829c',
  400: '#356886',
  500: '#114d71',
  600: '#0f4669',
  700: '#0c3d5e',
  800: '#0a3454',
  900: '#052542',
  A100: '#78b4ff',
  A200: '#4598ff',
  A400: '#127cff',
  A700: '#006ff7',

  light: '#687F92',
  main: '#114d71',
  dark: '#0f4669',
  contrastText: '#fff',
};

export const OvSuccess = {
  50: '#e9efe0',
  100: '#c9d7b3',
  200: '#a5bd80',
  300: '#81a24d',
  400: '#668e26',
  500: '#4b7a00',
  600: '#447200',
  700: '#3b6700',
  800: '#335d00',
  900: '#234a00',
  A100: '#afff7e',
  A200: '#8fff4b',
  A400: '#6fff18',
  A700: '#60fd00',
  light: '#668e26',
  main: '#4b7a00',
  dark: '#447200',
  contrastText: '#fff',
};

export const OvError = {
  50: '#f8e0ea',
  100: '#edb3cb',
  200: '#e180a8',
  300: '#d44d85',
  400: '#cb266b',
  500: '#c20051',
  600: '#bc004a',
  700: '#b40040',
  800: '#ac0037',
  900: '#9f0027',
  A100: '#ffc9d3',
  A200: '#ff96a8',
  A400: '#ff637e',
  A700: '#ff4a69',

  light: '#cb266b',
  main: '#c20051',
  dark: '#bc004a',
  contrastText: '#fff',
};
