import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    height: 32,
    width: '100%',
    background: theme.palette.grey[600],
    zIndex: 1300,
    position: 'relative'
  },
  footerText: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    color: theme.palette.common.white
  },
  footerContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  footerLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 40,
    height: '100%',
    position: 'absolute'
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerContainer}>
      <Box className={classes.footerLogoWrapper}>
        <img alt="logo" src="/static/OV_logo_white.svg" />
      </Box>
      <Box className={classes.footerContentWrapper}>
        <Typography className={classes.footerText}>
          {`Copyright 2021-${new Date().getFullYear()} One Village. All right reserved.`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
