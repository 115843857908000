import { createSlice } from '@reduxjs/toolkit';
import { CalendarEventService } from 'shared';
import { AppThunk } from 'src/store';
import { TourInfoMapper } from 'src/utils/TourMapper';

export interface TourState {
  tourMeetingDetails: any;
}

const initialState: TourState = { tourMeetingDetails: null };

const slice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTourMeetingDetails(state: TourState, action: any) {
      state.tourMeetingDetails = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const calendarEventService = CalendarEventService.getInstance<
  CalendarEventService
>();

export const getTourSettings = (): AppThunk => dispatch => {
  return calendarEventService.getEventByType('Tour').then(({ data }) => {
    dispatch(
      slice.actions.setTourMeetingDetails({
        ...TourInfoMapper({
          ...data,
          bufferAfterMeeting: String(data.bufferAfterMeeting)
        })
      })
    );
    return {
      ...TourInfoMapper({
        ...data,
        bufferAfterMeeting: String(data.bufferAfterMeeting)
      })
    };
  });
};

export default slice;
