import React from 'react';
import type { FC } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  CssBaseline,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import DialogsProvider from './components/core/Dialogs/Provider';
import AMPSettings from './components/AMPSettings';
import { LicenseInfo } from '@material-ui/x-grid';
import { XGridLicenseKey } from './config';
import SubscriptionLimitsExceededProvider from './components/SubscriptionLimitsExceeded/SubscriptionLimitsExceededProvider';
import { ToastProvider } from './components/core/ToastManager';
import SignalRProvider from './contexts/SignalrContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC = () => {

  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  LicenseInfo.setLicenseKey(XGridLicenseKey);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AMPSettings />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <ToastProvider>
              <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                <Router>
                  <SubscriptionLimitsExceededProvider>
                    <SignalRProvider>
                      <DialogsProvider>
                        <AuthProvider>
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          {/* <CookiesNotification /> */}
                          {/* <SettingsNotification /> */}
                          <DndProvider backend={HTML5Backend}>
                            {renderRoutes(routes)}
                          </DndProvider>
                        </AuthProvider>
                      </DialogsProvider>
                    </SignalRProvider>
                  </SubscriptionLimitsExceededProvider>
                </Router>
              </Sentry.ErrorBoundary>
            </ToastProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
