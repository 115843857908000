import React, { FC } from 'react';
import { makeStyles, Switch } from '@material-ui/core';
import { Theme } from 'src/theme';

export interface LoadingInfo {
  value: boolean;
  text?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  switchRoot: {
    width: 53,
    height: 32,
    padding: 0,
    margin: theme.spacing(1),
    display: 'flex',
    right: 0,
    top: 0
  },
  switchBase: {
    padding: 1,
    color: '#687F92',
    '&$checked': {
      transform: '#fff(16px)',
      color: '#4E9AAC',
      '& + $track': {
        backgroundColor: '#c8c8c8',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#fff',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 30,
    height: 30
    // backgroundColor: "#4E9AAC",
  },
  track: {
    borderRadius: 38 / 2,
    border: `1px solid "#4E9AAC"`,
    backgroundColor: '#c8c8c8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}));

export interface MySiteSettingProps {
  checked?: boolean;
  onChange?: () => void;
}

const ToggleSwitch: FC<MySiteSettingProps> = ({ checked, onChange }) => {
  const classes = useStyles();

  return (
    <Switch
      color="primary"
      name="checked"
      inputProps={{ 'aria-label': 'primary checkbox' }}
      checked={checked}
      onChange={onChange}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.switchRoot,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  );
};

export default ToggleSwitch;
