import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { createContext, useCallback, useEffect } from 'react';
import { Theme } from 'src/theme';
import CheckIcon from '@material-ui/icons/Check';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

interface IToastContext {
  addToast: (content: string, type?: string) => void;
  removeToast: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#9089FF',
    color: theme.palette.common.white,
    height: theme.spacing(10),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1)
  },
  leadConfirmRoot: {
    background: '#EFF7E3',
    color: '#3F7F8D',
    height: theme.spacing(7),
    width: 426,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.125),
    paddingRight: theme.spacing(1.625),
    borderRadius: theme.spacing(0.5),
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
  },
  errorRoot: {
    background: '#FF6B6B',
    color: theme.palette.common.white,
    height: theme.spacing(10),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1)
  },
  containerRoot: {
    position: 'fixed',
    top: theme.spacing(6.5),
    left: '50%',
    transform: 'translateX(-50%)',
    width: '40%',
    zIndex: 10000000
  },
  messageWrapper: {
    marginLeft: theme.spacing(1)
  }
}));

const Ctx = createContext<IToastContext | null>(null);

const ToastContainer = props => {
  const classes = useStyles();
  return <Box className={classes.containerRoot} {...props} />;
};
const Toast = ({ children, onDismiss, type }) => {
  const classes = useStyles();
  return (
    <>
      {type === 'leadStatusConfirm' ? (
        <Box onClick={onDismiss} className={classes.leadConfirmRoot}>
          <Box display="flex">
            <img
              src="/static/images/enrollment/toast_check.svg"
              alt="check_icon"
            />
            <Typography className={classes.messageWrapper}>
              {children}
            </Typography>
          </Box>
          <IconButton>
            <img
              src="/static/images/enrollment/toast_close.svg"
              alt="check_icon"
            />
          </IconButton>
        </Box>
      ) : (
        <Box
          className={type === 'success' ? classes.root : classes.errorRoot}
          onClick={onDismiss}
        >
          {type === 'success' ? <CheckIcon /> : <NewReleasesIcon />}
          <Typography className={classes.messageWrapper}>{children}</Typography>
        </Box>
      )}
    </>
  );
};

let toastCount: number = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = useCallback(
    (content: string, type: string = 'success') => {
      const id = toastCount++;
      const toast = { content, type, id };
      setToasts([...toasts, toast]);
    },
    [toasts]
  );
  const removeToast = useCallback(
    (id: number) => {
      const newToasts = toasts.filter(t => t.id !== id);
      setToasts(newToasts);
    },
    [toasts]
  );
  // avoid creating a new fn on every render
  const onDismiss = (id: number) => () => removeToast(id);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        removeToast(toasts[0].id);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [toasts, removeToast]);

  return (
    <Ctx.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer>
        {toasts.map(({ content, id, type, ...rest }) => (
          <Toast
            key={id}
            Toast={Toast}
            type={type}
            onDismiss={onDismiss(id)}
            {...rest}
          >
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);
