import React, { FC } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useSelector } from 'src/store';
import type { Theme } from 'src/theme';

interface NotificationBadgeProps {};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 16,
        height: 16,
        borderRadius: '100%',
        background: '#9089FF',
        fontFamily: "'Montserrat', sans-serif",
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 11,
        color: theme.palette.common.white,
        marginRight: 10
    }
}));

const NotificationBadge: FC<NotificationBadgeProps> = () => {
    const classes = useStyles();
    const unreadNotification = useSelector((state) => state.dashboard.unreadNotification);
    if(unreadNotification < 1) return null;
    return (
        <Box className={classes.container}>{unreadNotification}</Box>
    );
};

export default NotificationBadge;