import React, { ReactNode, useEffect, FC } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import NavItem from './NavItem';
import { useDispatch, useSelector } from 'react-redux';
import { setValuesChanged } from 'src/slices/basic-info';
import MobileNavItem from './MobileNavItem';
interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  info?: ReactNode;
  items?: Item[];
  title: string;
  notification?: boolean;
  iconPath?: string;
  icon?: any;
}

interface Section {
  items: Item[];
  subheader?: string;
}

const sections: Section[] = [
  {
    items: [
      {
        title: 'Home',
        href: '/app/dashboard',
        notification: true,
        iconPath: '/static/icons/home.svg'
      }
    ]
  },
  {
    subheader: 'Promote',
    items: [
      {
        title: 'Website',
        href: '/app/sites/my-site',
        iconPath: '/static/icons/microsite.svg'
      }
    ]
  },
  {
    subheader: 'Engage',
    items: [
      {
        title: 'Inbox',
        href: '/app/inbox',
        iconPath: '/static/icons/chat.svg'
      },
      {
        title: 'Leads',
        href: '/app/enroll/leads',
        iconPath: '/static/icons/leads.svg'
      },
      {
        title: 'Tours',
        href: '/app/enroll/tours',
        iconPath: '/static/icons/tours.svg'
      },
      {
        title: 'Families',
        href: '/app/families',
        iconPath: '/static/icons/families.svg'
      }
    ]
  },
  {
    subheader: 'Grow',
    items: [
      {
        title: 'Enrollment',
        href: '/app/registration/registration',
        iconPath: '/static/icons/child.svg'
      }
    ]
  }
];

const settingsSection: Section[] = [
  {
    subheader: 'Manage',
    items: [
      {
        title: 'My Center',
        iconPath: '/static/icons/my-center.svg',
        items: [
          {
            title: 'Settings',
            href: '/app/school/my-center/basic',
            iconPath: '/static/icons/settings.svg'
          },
          {
            title: 'Library',
            href: '/app/library',
            iconPath: '/static/icons/library.svg'
          }
        ]
      }
    ]
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  mobileCompactDrawer: {
    width: 96,
    top: 72
  },
  desktopDrawer: {
    width: 218,
    top: 72,
    bottom: 32,
    height: '100%',
    background: theme.palette.common.white
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    width: 160,
    height: 17
  },
  icon: {
    background: '#EBF6F6'
  },
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listStyles: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 32
  },
  subHeader: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 900,
    fontFamily: '"Montserrat", sans-serif',
    fontStyle: 'normal'
  },
  userDetailsContainer: {
    display: 'flex',
    width: '100%'
  },
  userDetailsContainerBorder: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #DCDCDC'
  },
  userDetailsContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  userImageWrapper: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  username: {
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    fontFamily: '"Montserrat", sans-serif',
    fontStyle: 'normal',
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: theme.spacing(2)
  },
  userEmail: {
    color: theme.palette.text.secondary,
    fontFamily: '"Source Sans Pro", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  navLogoWrapper: {
    width: '100%',
    height: 80,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    background: 'rgba(244, 244, 244, 0.4)'
  },
  schoolTextWrapper: {
    fontSize: 20,
    fontStyle: 'normal',
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 15px'
  },
  routerLinkStyle: {
    textDecoration: 'none',
    width: '100%'
  },
  headingTxt: {
    fontSize: '18px',
    padding: '0 10px',
    float: 'left',
    width: '85%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  menuList: {
    padding: 0
  },
  schoolLogo: {
    paddingTop: 3
  },
  button: {
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  logoutLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  navItemLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  profileImage: {
    borderRadius: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: 'cover'
  }
}));

function renderMobileNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceMobileChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceMobileChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open =
      item.items.filter(navItem =>
        matchPath(pathname, {
          path: navItem.href,
          exact: false
        })
      ).length > 0;

    acc.push(
      <MobileNavItem
        depth={depth}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        iconPath={item.iconPath}
        icon={item.icon}
        notification={item.notification}
      >
        {renderMobileNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </MobileNavItem>
    );
  } else {
    acc.push(
      <MobileNavItem
        depth={depth}
        href={item.href}
        info={item.info}
        key={key}
        title={item.title}
        iconPath={item.iconPath}
        icon={item.icon}
        notification={item.notification}
      />
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open =
      item.items.filter(navItem =>
        matchPath(pathname, {
          path: navItem.href,
          exact: false
        })
      ).length > 0;

    acc.push(
      <NavItem
        depth={depth}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        iconPath={item.iconPath}
        icon={item.icon}
        notification={item.notification}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        info={item.info}
        key={key}
        title={item.title}
        iconPath={item.iconPath}
        icon={item.icon}
        notification={item.notification}
      />
    );
  }

  return acc;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { hasValuesChanged } = useSelector(state => state['basicInfo']);
  const matches = useMediaQuery('(min-width:1366px)');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setValuesChanged(false));
  }, [hasValuesChanged, dispatch]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map((section, index) => (
            <List
              className={classes.listStyles}
              key={section.subheader || index}
              subheader={
                <ListSubheader
                  className={classes.subHeader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          {settingsSection.map((section, index) => (
            <List
              className={classes.listStyles}
              key={section.subheader || index}
              subheader={
                <ListSubheader
                  className={classes.subHeader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
      <Box className={classes.navLogoWrapper}>
        <img src="/static/logo_ov_nav.svg" alt="ov_navLogo" />
      </Box>
    </Box>
  );

  const mobileContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map((section, index) => (
            <List
              className={classes.listStyles}
              key={section.subheader || index}
              subheader={
                <ListSubheader
                  className={classes.subHeader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderMobileNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          {settingsSection.map((section, index) => (
            <List
              className={classes.listStyles}
              key={section.subheader || index}
              subheader={
                <ListSubheader
                  className={classes.subHeader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderMobileNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
      <Box className={classes.navLogoWrapper}>
        <img src="/static/logo_ov_nav.svg" alt="ov_navLogo" />
      </Box>
    </Box>
  );

  return (
    <>
      {matches ? (
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileCompactDrawer }}
          open
          variant="persistent"
        >
          {mobileContent}
        </Drawer>
      )}
    </>
  );
};

export default NavBar;
